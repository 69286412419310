import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';  // Adjust the import path according to your project structure

const NavigationContext = createContext();

const rootPaths = ['/home', '/basket', '/profile', '/scan', '/search', '/onboarding'];
const publicPaths = ['/', '/splash', '/onboarding', '/auth', '/login', '/reset-password', '/login/otp'];

const pathMatch = (stackPath, targetPath) => {
    if (typeof stackPath !== 'string' || typeof targetPath !== 'string') {
        return false;
    }
    const stackSegments = stackPath.split('/');
    const targetSegments = targetPath.split('/');
    if (stackSegments.length !== targetSegments.length) return false;
    return stackSegments.every((segment, i) => segment === targetSegments[i] || segment.startsWith(':'));
};

export const NavigationProvider = ({ children }) => {
    const [historyStack, setHistoryStack] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { user, hasToken } = useAuth();

    useEffect(() => {
        // Check authentication status and handle redirection
        if (!publicPaths.includes(location.pathname) && !rootPaths.includes(location.pathname)) {
            return;
        } else if (!hasToken() || !user) {
            if (!publicPaths.includes(location.pathname)) {
                navigate('/auth');
            }
        } else if (publicPaths.includes(location.pathname)) {
            navigate('/home');
        }
    }, [user, hasToken, location.pathname, navigate]);

    const pushPage = useCallback((path, state = {}, saveInHistory = true) => {
        setHistoryStack((prevStack) => {
            let newStack;

            if (rootPaths.includes(path)) {
                // Reset the stack if the target path is a root path
                newStack = [{ path, state }];
            } else if (saveInHistory) {
                const existingIndex = prevStack.findIndex(stackItem => pathMatch(stackItem.path, path));

                if (existingIndex !== -1) {
                    // Path exists in the stack, slice up to and including that index
                    newStack = prevStack.slice(0, existingIndex + 1);
                } else {
                    // Path does not exist, add to stack
                    const newEntry = { path, state };

                    // Update the previous entry's state with the current state if it exists
                    if (prevStack.length > 0) {
                        const prevEntry = { ...prevStack[prevStack.length - 1], state: { ...prevStack[prevStack.length - 1].state, ...state } };
                        newStack = [...prevStack.slice(0, -1), prevEntry, newEntry];
                    } else {
                        newStack = [...prevStack, newEntry];
                    }
                }
            } else {
                newStack = prevStack;
            }

            console.log(`Navigating to: ${path}`, `History stack updated:`, newStack);
            return newStack;
        });
    }, []);

    const navigateWithHistory = useCallback((path, options = {}) => {
        let saveInHistory = true;
        let state = {};

        // Check if options is a boolean or an object
        if (typeof options === 'boolean') {
            saveInHistory = options;
        } else if (typeof options === 'object') {
            saveInHistory = options.saveInHistory !== undefined ? options.saveInHistory : true;
            state = options.state || {};
        }

        pushPage(path, state, saveInHistory);
        navigate(path, { state });
    }, [pushPage, navigate]);

    const backPress = useCallback(() => {
        setHistoryStack((prevStack) => {
            const newStack = prevStack.slice(0, -1);
            const previousEntry = newStack.length > 0 ? newStack[newStack.length - 1] : { path: rootPaths[0], state: {} };

            // Navigate to the previous path and pass the associated state
            navigate(previousEntry.path, { state: previousEntry.state });
            return newStack;
        });
    }, [navigate]);

    const isRootPage = useCallback(() => {
        return historyStack.length <= 1 && rootPaths.includes(historyStack[0]?.path);
    }, [historyStack]);

    const isActive = useCallback((path) => {
        const normalizedPath = `/${path}`.replace('//', '/');
        return location.pathname.startsWith(normalizedPath);
    }, [location.pathname]);

    useEffect(() => {
        console.log('Current Navigation Stack:', historyStack);
    }, [historyStack]);

    return (
        <NavigationContext.Provider value={{ navigate: navigateWithHistory, backPress, isRootPage, isActive, historyStack }}>
            {children}
        </NavigationContext.Provider>
    );
};

/**
 * @typedef {Object} NavigationContextValue
 * @property {Function} navigate - Function to navigate to a specific path with options for state and history handling.
 * @property {Function} backPress - Function to navigate back to the previous page.
 * @property {Function} isRootPage - Function to check if the current page is a root page.
 * @property {Function} isActive - Function to check if a given path is active.
 * @property {Array<Object>} historyStack - Array representing the navigation history stack.
 * @property {string} historyStack[].path - The path of a specific entry in the history stack.
 * @property {Object} [historyStack[].state] - Optional state associated with the path.
 */

/**
 * Custom hook to use the NavigationContext.
 * @returns {NavigationContextValue} The value provided by the NavigationContext.
 */

export const useNavigation = () => useContext(NavigationContext);
