import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useSocket } from './SocketContext';
import { collectSegments, packageSegments, pickupSegments, deliverySegments } from 'constants/orderSegments';
import OrderRequest from 'components/OrderRequest';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
import { USER_ROLES } from 'utils/constants';

// TODO: change delivery/package/pickup to shopper/pharmacy/customer

/**
 * @typedef {Object} Product
 * @property {number} id - Unique identifier for the product in the order
 * @property {number} productId - Reference to the master product ID
 * @property {number} quantity - Number of items ordered
 * @property {number} price - Price per unit
 * @property {?number} productId_Dyn_Sort - Dynamic sorting field for product ID
 * @property {string} name - Product name/title
 * @property {string} packaging - Type of packaging (e.g., "ST" for standard)
 * @property {number} packageQuantity - Number of units in package
 */

/**
 * @typedef {Object} Customer
 * @property {number} id - Unique identifier for the customer
 * @property {string} integrationId - UUID for external system integration
 * @property {string} lastName - Customer's last name
 * @property {string} firstName - Customer's first name
 * @property {string} phoneNumber - Contact phone number
 * @property {string} userPic - URL or base64 of customer's profile picture
 */

/**
 * @typedef {Object} Pharmacy
 * @property {number} id - Unique identifier for the pharmacy
 * @property {?string} created - Creation date
 * @property {?number} createdBy - User ID who created the record
 * @property {string} updated - Last update date (format: "DD.MM.YYYY HH:mm")
 * @property {number} updatedBy - User ID who last updated the record
 * @property {string} integrationId - UUID for external system integration
 * @property {number} ownerId - ID of pharmacy owner
 * @property {string} name - Pharmacy name
 * @property {string} email - Contact email
 * @property {string} telefon - Contact phone number
 * @property {string} website - Pharmacy website URL
 * @property {string} publicPermitHolder - Public permit information
 * @property {string} fax - Fax number
 * @property {string} atu - Tax identification number
 * @property {string} bio - Pharmacy description
 * @property {string} note - Additional notes
 * @property {string} pharmacyAPIP - API endpoint
 * @property {string} pharmacyAPUser - API username
 * @property {string} pharmacyAPPass - API password
 * @property {string} cityName - City location
 * @property {string} streetName - Street address
 * @property {string} postalCode - Postal code
 * @property {string} geoLatitude - Geographic latitude
 * @property {string} geoLongitude - Geographic longitude
 * @property {string} ownerId_Dyn_Sort - Dynamic sorting field for owner
 */

/**
 * @typedef {Object} Shopper
 * @property {number} id - Unique identifier for the shopper
 * @property {string} integrationId - UUID for external system integration
 * @property {string} lastName - Shopper's last name
 * @property {string} firstName - Shopper's first name
 * @property {string} phoneNumber - Contact phone number
 * @property {?string} userPic - URL or base64 of shopper's profile picture
 */

/**
 * @typedef {Object} OrderDetails
 * @property {number} id - Unique identifier for the order
 * @property {string} created - Order creation date (format: "DD.MM.YYYY HH:mm")
 * @property {string} integrationId - UUID for order tracking
 * @property {number} customerId - Reference to customer ID
 * @property {string} customerUUID - Customer's UUID for external systems
 * @property {?number} pharmacyId - ID of assigned pharmacy (null if unassigned)
 * @property {string} pharmacyUUID - UUID of assigned pharmacy
 * @property {?number} shopperId - ID of assigned shopper (null if unassigned)
 * @property {string} shopperUUID - UUID of assigned shopper
 * @property {string} type - Order type identifier
 * @property {number} deliveryStatus - Current status of delivery (1: pending, etc.)
 * @property {Product[]} products - Array of products in the order
 * @property {number} totalPrice - Total order amount including all products
 * @property {Customer} customer - Customer details object
 * @property {Pharmacy} pharmacy - Pharmacy details object
 * @property {Shopper} shopper - Shopper details object
 * @property {number} customerId_Dyn_Sort - Dynamic sorting field for customer ID
 * @property {?number} pharmacyId_Dyn_Sort - Dynamic sorting field for pharmacy ID
 * @property {?number} shopperId_Dyn_Sort - Dynamic sorting field for shopper ID
 */

/**
 * @typedef {Object} OrderContextValue
 * @property {?OrderDetails} orderDetails - Current order being processed
 * @property {boolean} isOverlayVisible - Controls visibility of the overlay UI
 * @property {number} activeStep - Current step in the order process (0-based index)
 * @property {function(): void} resetOrderContext - Clears all order context state and localStorage
 * @property {function(number=): void} showOverlay - Displays overlay with optional initial step
 * @property {function(): void} hideOverlay - Hides the current overlay
 * @property {function(): void} proceedToNextStep - Advances to next step in order process
 * @property {function(): void} resetSteps - Resets step counter to 0
 * @property {function(OrderDetails, number=): void} startMatchingProcess - Initiates order matching with optional starting step
 * @property {function(): void} stopMatchingProcess - Terminates current matching process
 * @property {function(number=): ?string} getCurrentPage - Gets URL for current step based on user role
 * @property {function(React.ReactNode): void} showOverlayContent - Shows custom content in overlay
 * @property {function(): void} hideOverlayContent - Removes custom content from overlay
 * @property {function(): Promise<void>} refetchOrder - Refreshes order details from server
 */

// Context initialization
const OrderContext = createContext(/** @type {React.Context<OrderContextValue>} */(null));
const LOCAL_STORAGE_KEY = 'orderContext';

export const OrderProvider = ({ children }) => {
    // State variables
    const [orderDetails, setOrderDetails] = useState(null);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [overlayContent, setOverlayContent] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const { getUser } = useUser();
    const user = getUser();

    // Create a base context value that's always available
    const contextValue = {
        orderDetails: null,
        isOverlayVisible: false,
        activeStep: 0,
        resetOrderContext: () => { },
        showOverlay: () => { },
        hideOverlay: () => { },
        proceedToNextStep: () => { },
        resetSteps: () => { },
        startMatchingProcess: () => { },
        stopMatchingProcess: () => { },
        getCurrentPage: () => null,
        showOverlayContent: () => { },
        hideOverlayContent: () => { },
        refetchOrder: () => { },
    };

    // If there's no user, return the provider with empty/no-op functions
    if (!user) {
        return (
            <OrderContext.Provider value={contextValue}>
                {children}
            </OrderContext.Provider>
        );
    }

    const { sendRequest } = useRequest();
    const { sendMessage, onRefetchData } = useSocket();

    // Load state from localStorage on mount
    useEffect(() => {
        console.log("MOUNTED: useEffect executed");
        const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
        console.log("Saved State:", savedState);
        if (savedState) {
            const parsedState = JSON.parse(savedState);
            setActiveStep(parsedState.activeStep || 0);
            setOrderDetails(parsedState.orderDetails || null);
        }
    }, []);

    // Save state to localStorage whenever it changes
    useEffect(() => {
        const stateToSave = {
            orderDetails,
            activeStep,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateToSave));
    }, [orderDetails, activeStep]);

    const refetchOrder = async () => await sendRequest({
        method: 'POST',
        url: `/module/order/getOrder`,
        data: {
            "orderUUID": orderDetails.integrationId,
        }
    }, 3, 2000).then((response) => {
        setOrderDetails(response.data)

    }).catch((error) => {
        console.log("error orderIsReady", error);
    })

    useEffect(() => {
        if (orderDetails) {
            refetchOrder()
        }
    }, [activeStep]);

    // Callback functions
    const showOverlayContent = useCallback((content) => {
        setOverlayContent(content);
        setIsOverlayVisible(true);
    }, []);

    const hideOverlayContent = useCallback(() => {
        setOverlayContent(null);
        setIsOverlayVisible(false);
    }, []);

    const showOverlay = useCallback((initialStep = 0) => {
        setActiveStep(initialStep);
        setIsOverlayVisible(true);
    }, []);

    const hideOverlay = useCallback(() => {
        setIsOverlayVisible(false);
        setOverlayContent(null);
    }, []);

    const resetSteps = useCallback(() => {
        setActiveStep(0);
    }, []);

    const startMatchingProcess = useCallback(
        (order, activeStep = 0) => {
            setOrderDetails(order);
            setActiveStep(activeStep);
            setIsOverlayVisible(false);
        },
        []
    );

    const proceedToNextStep = useCallback(() => {
        setActiveStep((prevStep) => prevStep + 1);
    }, []);

    const stopMatchingProcess = useCallback(() => {
        resetSteps();
        hideOverlay();
        setActiveStep(0);
        setOrderDetails(null);
    }, [resetSteps, hideOverlay]);

    const getCurrentPage = useCallback((activeStep = 0) => {
        if (user.userRole === USER_ROLES.CONSUMER_ROLE) {
            return `/matchmaking/pickup/${activeStep}`;
        } else if (user.userRole === USER_ROLES.PHARMACY_ROLE) {
            return `/matchmaking/package/${activeStep}`;
        } else if (user.userRole === USER_ROLES.SHOPPER_ROLE) {
            return `/matchmaking/delivery/${activeStep}`;
        }
        return null; // Default fallback if no match
    }, [user.userRole]);

    const resetOrderContext = useCallback(() => {
        setActiveStep(0);
        setOrderDetails(null);
        setIsOverlayVisible(false);
        setOverlayContent(null);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }, []);

    useEffect(() => {
        const cleanupRefetchData = onRefetchData((data) => {
            if (data) {
                console.log({ data });
                if (data.notificationType.includes('ORDER_PROGRESS_')) {
                    setActiveStep(Number(data.notificationType.split("_")[2]));
                }
                if (data.notificationType.includes('ORDER_REQUEST')) {
                    const { uuid } = data.notificationContent;
                    showOverlayContent(
                        <OrderRequest uuid={uuid} />
                    );
                }
                if (data.notificationType.includes('ORDER_CANCELED')) {
                    alert(data.notificationContent)
                    stopMatchingProcess()
                    resetOrderContext()
                }
            }
        });

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [onRefetchData, showOverlayContent]);

    // Function to get overlay content based on the current state
    const getOverlayContent = () => {
        if (overlayContent) {
            return overlayContent;
        }
        // Do not return ProgressOverlay here
        return null;
    };

    // Provide state and functions to all components in the tree
    return (
        <OrderContext.Provider
            value={{
                orderDetails,
                isOverlayVisible,
                activeStep,
                resetOrderContext,
                showOverlay,
                hideOverlay,
                proceedToNextStep,
                resetSteps,
                startMatchingProcess,
                stopMatchingProcess,
                getCurrentPage,
                showOverlayContent,
                hideOverlayContent,
                refetchOrder,
            }}
        >
            {children}
            {isOverlayVisible && overlayContent && (
                <div className="fixed inset-0 bg-black/85 flex items-center justify-center z-50">
                    <div className="relative w-full h-full flex items-center justify-center">
                        {overlayContent}
                    </div>
                </div>
            )}
        </OrderContext.Provider>
    );
};

/**
 * Custom hook to use the OrderContext
 * @returns {OrderContextValue}
 */
export const useOrder = () => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error('useOrder must be used within an OrderProvider');
    }
    return context;
};
