import React, { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';
import { useNavigation } from '../../../context/NavigationContext';
import {
  BringzzPageContainer,
  BringzzButton,
  BringzzIcon,
  BringzzInput,
  BringzzText,
  BringzzCountryDropdown,
  BringzzModal,
} from '@bringzz/components';

const EditAddressView = () => {
  const { backPress, navigate } = useNavigation();
  const { sendRequest } = useRequest();
  const { user } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    streetName: '',
    houseNumber: '',
    entrance: '',
    floor: '',
    door: '',
    postalCode: '',
    cityName: '',
    note: '',
  });
  const [country, setCountry] = useState({
    name: 'Austria',
    code: 'AT',
  });
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [integrationId, setIntegrationId] = useState(null);

  const labelOptions = [
    { id: 'home', icon: 'IconHome', name: 'Home' },
    { id: 'work', icon: 'IconHandbag', name: 'Work' },
    { id: 'partner', icon: 'IconHeartCopy', name: 'Partner' },
    { id: 'other', icon: 'IconPlus', name: 'Other' },
  ];

  const searchParams = new URLSearchParams(window.location.search);
  const isEdit = searchParams.has('edit');
  const isNew = searchParams.get('new') === 'true';

  useEffect(() => {
    if (isEdit) {
      const integrationId = searchParams.get('edit');
      setIntegrationId(integrationId);
      fetchAddressDetails(integrationId);
    }
    const geoLatitude = searchParams.get('geoLatitude');
    const geoLongitude = searchParams.get('geoLongitude');
    const cityNameParam = searchParams.get('cityName');

    if (cityNameParam) {
      const [city, country] = cityNameParam.split(',').map(item => item.trim());
      setFormValues((prev) => ({
        ...prev,
        cityName: city,
      }));
      setCountry((prev) => ({
        ...prev,
        name: country,
      }));
    }

    if (geoLatitude && geoLongitude) {
      setFormValues((prev) => ({
        ...prev,
        geoLatitude: geoLatitude,
        geoLongitude: geoLongitude,
      }));
    }
  }, [isEdit]);

  const fetchAddressDetails = async (integrationId) => {
    try {
      const response = await sendRequest({
        method: 'GET',
        url: `/module/address/getAddressByIntegrationId?integrationId=${integrationId}`,
      });
      if (response.status === 'success' && response.data) {
        console.log('response', response.data);
        setFormValues(response.data);
        setSelectedLabel(response.data.addressLabel || null);
        setCountry({
          name: response.data.countryName || 'Austria',
        });
      }
    } catch (error) {
      console.error('Failed to fetch address details', error);
    }
  };

  const handleInputChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveAddress = async () => {
    if (isNew) {
      await addNewAddress();
    } else if (isEdit) {
      await updateAddress();
    }
  };

  const addNewAddress = async () => {
    try {
      const addressData = {
        addressData: {
          streetName: formValues.streetName || null,
          houseNumber: formValues.houseNumber || null,
          belongsTo: user?.integrationId || null,
          cityName: formValues.cityName || null,
          postalCode: formValues.postalCode || null,
          region: formValues.region || null,
          countryName: formValues.countryName || country.name || null,
          entrance: formValues.entrance || null,
          floor: formValues.floor || null,
          door: formValues.door || null,
          note: formValues.note || null,
          addressLable: selectedLabel || null,
          addressIcon: labelOptions.find(label => label.id === selectedLabel)?.icon || null,
          geoLatitude: formValues.geoLatitude || null,
          geoLongitude: formValues.geoLongitude || null,
        }
      };
      const response = await sendRequest({
        method: 'POST',
        url: '/module/address/setAddress',
        data: addressData,
      });

      if (response.status === 'success') {
        navigate('/address/');
      }
    } catch (error) {
      console.error('Failed to add new address', error);
    }
  };

  const updateAddress = async () => {
    try {
      const addressData = {
        integrationId: formValues.integrationId,
        addressData: {
          streetName: formValues.streetName,
          houseNumber: formValues.houseNumber,
          cityName: formValues.cityName,
          postalCode: formValues.postalCode,
          countryName: country.name,
          entrance: formValues.entrance || null,
          floor: formValues.floor || null,
          door: formValues.door || null,
          note: formValues.note || null,
          addressLabel: selectedLabel,
          geoLatitude: formValues.geoLatitude || null,
          geoLongitude: formValues.geoLongitude || null,
        },
      };

      const response = await sendRequest({
        method: 'PUT',
        url: '/module/address/updateAddress',
        data: addressData,
      });
      console.log('response', response);
      if (response.status === 'success') {
        navigate('/address/');
      } else {
        console.error('Failed to update address: ', response.message);
      }
    } catch (error) {
      console.error('Failed to update address', error);
    }
  };

  const handleDeleteAddress = async () => {
    try {
      const response = await sendRequest({
        method: 'POST',
        url: `/module/address/deleteAddressByIntegrationId`,
        data: {
          integrationId: integrationId,
        },
      });
      if (response.status === 'success') {
        navigate('/address/');
      }
    } catch (error) {
      console.error('Failed to delete address', error);
    }
  };

  const handleBackPress = () => {
    window.history.back();
  };

  const getNavigateUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isEdit = searchParams.has('edit');
    const isNew = searchParams.get('new') === 'true';

    let navigateUrl = '/address/location?';

    if (isEdit) {
      navigateUrl += `edit=${searchParams.get('edit')}&`;
    }
    if (isNew) {
      navigateUrl += `new=true&`;
    }

    if (formValues.cityName) {
      navigateUrl += `cityName=${formValues.cityName}&`;
    }

    return navigateUrl;
  };

  const handleNavigate = () => {
    const url = getNavigateUrl();
    window.location.href = url;
  };

  return (
    <BringzzPageContainer
      header={{
        left: (
          <BringzzIcon
            size="22"
            icon="IconChevronLeft"
            folder="LineIcons"
            className="cursor-pointer"
            onClick={handleBackPress}
          />
        ),
        title: <BringzzText tag="h2">{isNew ? 'Add New Address' : 'Edit Address'}</BringzzText>,
      }}
    >
      <div className="p-6 space-y-6">
        <div className="flex items-center space-x-3">
          <BringzzIcon
            icon="IconGeoLocation"
            folder="HealthIcons"
            size="30"
            className="rounded-full bg-gray-300 text-white"
          />
          <div onClick={handleNavigate}>
            <BringzzText tag="p" className="text-sm text-gray-500">
              {formValues.cityName}, {formValues.countryName}
            </BringzzText>
          </div>
        </div>
        <hr className="my-2 border-t-2 border-gray-300" />
        <BringzzText tag="h4" className="font-bold mb-2">
          Address label (optional)
        </BringzzText>
        <div className="flex justify-around">
          {labelOptions.map((label) => (
            <div
              key={label.id}
              className="flex flex-col cursor-pointer"
              onClick={() => setSelectedLabel(label.id)}
            >
              <div
                className={`w-10 h-10 flex items-center justify-center rounded-full border ${selectedLabel === label.id
                  ? 'bg-blue-500 border-blue-500'
                  : 'bg-gray-200 border-gray-200'
                  }`}
              >
                <BringzzIcon
                  size="24"
                  icon={label.icon}
                  folder="LineIcons"
                  className={selectedLabel === label.id ? 'text-white' : 'text-black'}
                />
              </div>
              <BringzzText tag="span" className="mt-1 text-sm">
                {label.name}
              </BringzzText>
            </div>
          ))}
        </div>

        <div className="flex gap-3">
          <BringzzInput
            id="streetName"
            placeholder="Musterstarsse 12"
            label="Street Name"
            value={formValues.streetName}
            onChange={(e) => handleInputChange('streetName', e.target.value)}
            containerClassname="w-full"
          />
          <BringzzInput
            id="houseNumber"
            placeholder="12"
            label="House Number"
            value={formValues.houseNumber}
            onChange={(e) => handleInputChange('houseNumber', e.target.value)}
            containerClassname="w-1/2"
          />
        </div>
        <div className="flex gap-3">
          <BringzzInput
            id="entrance"
            placeholder="entrance"
            label="Entrance (opt.)"
            value={formValues.entrance}
            onChange={(e) => handleInputChange('entrance', e.target.value)}
            containerClassname="w-full"
          />
          <BringzzInput
            id="floor"
            placeholder="floor"
            label="Floor (opt.)"
            value={formValues.floor}
            onChange={(e) => handleInputChange('floor', e.target.value)}
            containerClassname="w-full"
          />
          <BringzzInput
            id="door"
            label="Door (opt.)"
            placeholder="door"
            value={formValues.door}
            onChange={(e) => handleInputChange('door', e.target.value)}
            containerClassname="w-full"
          />
        </div>
        <div className="flex gap-3">
          <BringzzInput
            id="postalCode"
            placeholder="1040"
            label="Postal Code"
            value={formValues.postalCode}
            onChange={(e) => handleInputChange('postalCode', e.target.value)}
            containerClassname="w-1/3"
          />
          <BringzzInput
            id="cityName"
            label="City"
            placeholder="Wien"
            value={formValues.cityName}
            onChange={(e) => handleInputChange('cityName', e.target.value)}
            containerClassname="w-full"
          />
        </div>
        <div>
          <BringzzText tag="h4" className="font-bold mb-2">
            Country
          </BringzzText>
          <BringzzCountryDropdown
            className="w-full bg-[#F4F4F4] border border-gray-300 rounded-lg p-3 text-sm"
            mode="flag-name"
            value={country}
            onCountryChange={setCountry}
          />
        </div>
        <BringzzInput
          id="note"
          label="Note to rider"
          placeholder="e.g. entrance description, doorbell, etc."
          value={formValues.note}
          onChange={(e) => handleInputChange('note', e.target.value)}
          containerClassname="w-full"
          inputClassname="resize-none h-24"
          as="textarea"
        />
      </div>
      <div className="px-6 pb-6 flex justify-between gap-4">
        <BringzzButton className="border w-full border-black justify-center" onClick={handleSaveAddress}>
          {isNew ? 'Add Address' : 'Save'}
        </BringzzButton>
        {!isNew && (
          <BringzzButton
            className="bg-magic-lilac w-full justify-center"
            onClick={() => setModalOpen(true)}
          >
            Delete
          </BringzzButton>
        )}
      </div>
      {modalOpen && (
        <BringzzModal
          title={'Delete this address ?'}
          description={`This address will be permanently deleted.`}
          onCancel={() => setModalOpen(false)}
          confirmButtonText="Delete"
          onConfirm={handleDeleteAddress}
        />
      )}
    </BringzzPageContainer>
  );
};

export default EditAddressView;
