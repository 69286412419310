import React, { useState, useEffect } from "react";
import { BringzzPageContainer, BringzzIcon, BringzzText } from "@bringzz/components";
import { useNavigation } from '../../../context/NavigationContext';

const AddressScreen = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [address, setAddress] = useState("");
  const { navigate } = useNavigation();
  const [selectedAddress, setSelectedAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCoords, setSelectedCoords] = useState({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setSelectedCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setCurrentStep(2);
        },
        (error) => {
          console.error("Error getting location", error);
          setCurrentStep(1);
        }
      );
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBpJtJ2NNTVui0Kd7HSYlOzCctbIPfziBA&libraries=places`;
    script.async = true;
    script.onload = () => console.log("Google Maps API loaded");
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleAllowLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setSelectedCoords({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setCurrentStep(2);
      },
      (error) => {
        console.error("Error getting location", error);
        alert("Failed to get location. Please enable location services.");
      }
    );
  };

  const fetchSuggestions = (query) => {
    if (!query || !window.google || !window.google.maps || !window.google.maps.places) {
      setSuggestions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    const location = new window.google.maps.LatLng(selectedCoords.latitude, selectedCoords.longitude);

    if (selectedCoords.latitude && selectedCoords.longitude) {
      service.getPlacePredictions(
        { input: query, location, radius: 50000 },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions.map((p) => p.description));
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      console.error('Invalid coordinates');
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (!window.google || !window.google.maps || !window.google.maps.Geocoder) {
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: suggestion }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        setSelectedCoords({
          latitude: location.lat(),
          longitude: location.lng(),
        });
        setSelectedAddress(suggestion);
        setAddress(suggestion);
        setSuggestions([]);
      }
    });
  };

  const handleSaveAddress = () => {
    if (!selectedAddress || !selectedCoords.latitude || !selectedCoords.longitude) {
      alert("Please select a valid address.");
      return;
    }
  
    const searchParams = new URLSearchParams(window.location.search);
    const isEdit = searchParams.has('edit');
    const isNew = searchParams.get('new') === 'true';
    
    let navigateUrl;
      if (isEdit) {
      const editIntegrationId = searchParams.get('edit');
      navigateUrl = `/settings/account/address?edit=${editIntegrationId}&geoLatitude=${selectedCoords.latitude}&geoLongitude=${selectedCoords.longitude}&cityName=${selectedAddress}`;
    } else if (isNew) {
      navigateUrl = `/settings/account/address?new=true&geoLatitude=${selectedCoords.latitude}&geoLongitude=${selectedCoords.longitude}&cityName=${selectedAddress}`;
    }
  
    navigate(navigateUrl);
  };
  
  return (
    <BringzzPageContainer>
      <div className="flex items-center px-4 py-4 bg-white shadow-sm">
        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <BringzzIcon
            size='22'
            icon='IconChevronLeft'
            folder='LineIcons'
            className='cursor-pointer'
          />
        </div>
      </div>

      {currentStep === 1 && (
        <div className="h-full flex flex-col justify-center items-center">
          <p className="text-center px-4 mb-8">
            Allow Bringzz to use your location to fetch your current address or find nearby suggestions.
          </p>
          <div className="flex space-x-2">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
              onClick={handleAllowLocation}
            >
              Allow while using app
            </button>
            <button
              className="px-4 py-2 bg-gray-300 rounded-md"
              onClick={() => setCurrentStep(2)}
            >
              Don’t Allow
            </button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="h-full flex flex-col">
          {/* Search Bar at the Top */}
          <div className="mx-4 mt-4">
            <input
              type="text"
              placeholder="Search address"
              className="p-2 border border-gray-300 rounded-md w-full"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                fetchSuggestions(e.target.value);
              }}
            />
          </div>

          {/* Suggestions List */}
          <ul className="divide-y divide-gray-200 mx-4 mt-4">
            {suggestions.length > 0 ? (
              suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))
            ) : (
              <li className="py-2 text-gray-500">
                No suggestions found. Try another query.
              </li>
            )}
          </ul>

          {/* Share Location Button */}
          <button
            className="flex items-center text-blue-500 mt-4 px-4"
            onClick={() => handleSuggestionClick("My Current Location")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-5 h-5 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 9l4-4m0 0l4 4m-4-4v12"
              />
            </svg>
            Share current location
          </button>

          {/* Selected Address */}
          {selectedAddress && (
            <div className="mt-4 p-2 bg-gray-100 rounded-md">
              <strong>Registered Address:</strong>
              <p>{selectedAddress}</p>
            </div>
          )}
          
        </div>

      )}
      <div className="px-4 pb-6 mt-auto mb-20">
            <button
              className="w-full py-4 text-sm font-medium text-white bg-[#0056D2] rounded-full"
              onClick={handleSaveAddress}
            >
              <BringzzText
                tag='h3'
                className='text-normal text-white whitespace-pre-line truncate'
              >
                Save address
              </BringzzText>
            </button>
          </div>
    </BringzzPageContainer>
  );
};

export default AddressScreen;
