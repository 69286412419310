import React, { useRef, useState, useEffect } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const BasicMap = () => {
    const ref = useRef();
    const [map, setMap] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);

    useEffect(() => {
        if (!ref.current) return;

        const map = new window.google.maps.Map(ref.current, {
            center: { lat: 47.516231, lng: 14.550072 },
            zoom: 7,
            disableDoubleClickZoom: true,
            fullscreenControl: false,
            streetViewControl: true,
            mapTypeControl: false,
            styles: [{
                featureType: "poi",
                stylers: [{ visibility: "off" }]
            }],
        });

        const renderer = new window.google.maps.DirectionsRenderer();
        renderer.setMap(map);
        setDirectionsRenderer(renderer);
        setMap(map);
    }, []);

    useEffect(() => {
        if (!map || !directionsRenderer) return;

        const directionsService = new window.google.maps.DirectionsService();

        directionsService.route(
            {
                origin: "Vienna, Austria",
                destination: "Salzburg, Austria",
                travelMode: window.google.maps.TravelMode.BICYCLING,
            },
            (result, status) => {
                if (status === "OK") {
                    directionsRenderer.setDirections(result);
                }
            }
        );
    }, [map, directionsRenderer]);

    return <div ref={ref} className="h-full" />;
};

const BringzzNavigationMap = () => {
    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <span>loading</span>;
            case Status.FAILURE:
                return <span>Error</span>;
            case Status.SUCCESS:
                return <BasicMap />;
        }
    };

    return (
        <Wrapper
            apiKey={"AIzaSyAtms-7qsst_vD187mM3n6QVRNGrk1xC_4"}
            render={render}
        />
    );
};

export default BringzzNavigationMap;