import React, { useState, useEffect } from "react";
import { useNavigation } from "context/NavigationContext";
import {
  BringzzPageContainer,
  BringzzButton,
  BringzzIcon,
  BringzzText,
  BringzzInput,
} from "@bringzz/components";
import useUser from "hooks/useUser";
import useRequest from "hooks/useRequest";

const AddPharmacyEmployee = () => {
  const { backPress, navigate } = useNavigation();
  const { sendRequest, loading } = useRequest();
  const { getUser } = useUser();
  const loggedInUser = getUser();

  const [pharmacyId, setPharmacyId] = useState("");
  const [bio, setBio] = useState("");
  const [userId, setUserId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchPharmacyId = async () => {
      try {
        const response = await sendRequest({
          method: "GET",
          url: "/module/pharmacyteam/getPharmacyId",
        });

        if (response?.status === "success") {
          setPharmacyId(response.data.pharmacyId);
        } else {
          setErrorMessage(response?.message || "Failed to fetch pharmacy ID.");
        }
      } catch (error) {
        console.error("Error fetching pharmacy ID:", error);
        setErrorMessage("An error occurred while fetching pharmacy ID.");
      }
    };

    fetchPharmacyId();
  }, [sendRequest]);

  const handleSubmit = async () => {
    if (!bio.trim() || !userId.trim()) {
      setErrorMessage("Please fill in all the required fields.");
      return;
    }

    try {
      const response = await sendRequest({
        method: "POST",
        url: "/module/pharmacyteam/addPharmacyTeamMember",
        data: {
          data: {
            pharmacyId: parseInt(pharmacyId),
            bio,
            userId: parseInt(userId),
            created_by_id: loggedInUser.id,
          },
        },
      });

      if (response?.status === "success") {
        navigate("/settings/pharmacy/team", false);
      } else {
        setErrorMessage(
          response?.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.error("Error adding pharmacy employee:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <BringzzPageContainer
      header={{
        left: (
          <BringzzIcon
            size="22"
            icon="IconChevronLeft"
            folder="LineIcons"
            className="cursor-pointer"
            onClick={backPress}
          />
        ),
        title: <BringzzText tag="h2">Add Employee</BringzzText>,
      }}
      footer={
        <div className="pb-28 px-6">
          <BringzzButton
            size="lg"
            className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
            onClick={handleSubmit}
            disabled={loading}
          >
            Add Employee
          </BringzzButton>
        </div>
      }
    >
      <div className="p-6 space-y-4">
        {errorMessage && (
          <BringzzText tag="h3" className="text-red-500">
            {errorMessage}
          </BringzzText>
        )}

        <div>
          <BringzzText tag="label" className="block mb-1">
            User ID
          </BringzzText>
          <BringzzInput
            placeholder="Enter User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
        </div>

        <div>
          <BringzzText tag="label" className="block mb-1">
            Bio
          </BringzzText>
          <BringzzInput
            placeholder="Enter Employee Bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </div>
      </div>
    </BringzzPageContainer>
  );
};

export default AddPharmacyEmployee;
