import React, { useEffect, useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct,
    BringzzAccordion,
    BringzzButton,
    BringzzText
} from "@bringzz/components";
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useOrder } from 'context/OrderContext';
import BringzzCustomerCardSmall from 'components/BringzzCustomerCardSmall';
import { getOrderTypeString } from 'utils';
import BringzzOrderCardSmallAccordion from 'components/BringzzOrderCardSmallAccordion';
import { USER_ROLES } from 'utils/constants';
import useUser from 'hooks/useUser';
import BringzzReadOnlyMap from 'components/BringzzReadOnlyMap';
const OrderViewPage = () => {
    const { backPress, navigate } = useNavigation();
    const { uuid } = useParams();
    const { startMatchingProcess } = useOrder()
    const { sendRequest, data, error, loading } = useRequest();
    const [orderDetails, setOrderDetails] = useState()
    const { getUser } = useUser()
    const user = getUser()

    const fetchOrder = async () => {
        await sendRequest({
            method: 'POST',
            url: `/module/order/getOrder`,
            data: {
                "orderUUID": uuid
            }
        }).then((response) => {
            if (response.status === 'success') {
                setOrderDetails(response.data)
                console.log(response);
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        if (!uuid) {
            backPress()
        }
        else {
            fetchOrder()
        }
    }, [])

    const acceptOrder = async () => {
        if (orderDetails) {
            const data = await sendRequest({
                method: 'POST',
                url: `/module/order/acceptOrder`,
                data: {
                    "orderUUID": uuid
                }
            }).then((response) => {
                if (response.status === 'success') {
                    return response.data
                }
            }).catch((error) => {
                console.error(error);
            })

            if (data) {
                startMatchingProcess(orderDetails, 0)
                if (USER_ROLES.PHARMACY_ROLE === user.userRole) {
                    navigate('/matchmaking/package/0')
                } else if (USER_ROLES.SHOPPER_ROLE === user.userRole) {
                    navigate('/matchmaking/delivery/0')
                }
            }
        }
    }

    if (!orderDetails) {
        return (
            <BringzzPageContainer
                className="bg-white"
                header={{
                    left: (
                        <BringzzIcon
                            size='22'
                            icon='IconChevronLeft'
                            folder='LineIcons'
                            className='cursor-pointer'
                            onClick={backPress}
                        />
                    ),
                    title: "Loading Order..."
                }}
                authenticated={true}
            >
                <div className="text-center py-6">
                    <BringzzText tag="h3">Loading...</BringzzText>
                </div>
            </BringzzPageContainer>
        );
    }

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: `Order #${orderDetails.id}`
            }}
            footerHover={true}
            footer={<div className=''>
                <div className='p-4 justify-center text-center'>
                    <BringzzText tag='h5'>By tapping the accept button, I accept the order from my client. I agree to the terms and conditions and privacy policy.</BringzzText>
                    <div className='flex space-x-2 justify-center mt-2'>
                        <BringzzButton
                            className="bg-white text-red-400 border-red-400 border-2"
                            onClick={() => navigate('/')}
                            disabled={loading}
                        >
                            Decline
                        </BringzzButton>
                        <BringzzButton className="bg-magic-lilac"
                            onClick={acceptOrder}
                            disabled={loading}
                        >Accept</BringzzButton>
                    </div>
                </div>

            </div>}
            authenticated={true}
        >
            {orderDetails && <div className='px-6'>
                <div className='text-center my-4'>
                    <BringzzText tag='h1'>€ {orderDetails.totalPrice.toFixed(2) || 0}</BringzzText>
                    <BringzzText tag='h3'>{orderDetails.products.length} Items</BringzzText>
                </div>

                {USER_ROLES.SHOPPER_ROLE == user.userRole &&
                    <BringzzReadOnlyMap
                        startLocation={{ lat: 48.2082, lng: 16.3738 }} // Vienna
                        endLocation={{ lat: 48.1951, lng: 16.3457 }}   // Schönbrunn
                    />
                }

                <BringzzCustomerCardSmall customer={orderDetails.customer} />

                {user.userRole === USER_ROLES.SHOPPER_ROLE && (
                    <>
                        <div className="flex items-center justify-between p-3 border-b">
                            <div className="items-center flex gap-x-6">
                                <span>
                                    <BringzzText tag="h4" className="font-regular">
                                        Estimated total distance
                                    </BringzzText>
                                </span>
                            </div>
                            <span className="font-bold">
                                <BringzzText>9.9km</BringzzText>
                            </span>
                        </div>
                        <div className="flex items-center justify-between p-3 border-b">
                            <div className="items-center flex gap-x-6">
                                <span>
                                    <BringzzText tag="h4" className="font-regular">
                                        Estimated time
                                    </BringzzText>
                                </span>
                            </div>
                            <span className="font-bold">20min</span>
                        </div>
                        <div className="flex items-center justify-between p-3 border-b">
                            <div className="items-center flex gap-x-6">
                                <span>
                                    <BringzzText tag="h4" className="font-regular">
                                        Estimated points
                                    </BringzzText>
                                </span>
                            </div>
                            <span className="font-bold">XXpts</span>
                        </div>
                        <div className="flex items-center justify-between p-3 border-b">
                            <div className="items-center flex gap-x-6">
                                <span>
                                    <BringzzText tag="h4" className="font-regular">
                                        Estimated pay
                                    </BringzzText>
                                </span>
                            </div>
                            <span className="font-bold">XX€</span>
                        </div>
                        <div className="flex items-center justify-between p-3 border-b">
                            <div className="items-center flex gap-x-6">
                                <span>
                                    <BringzzText tag="h4" className="font-regular">
                                        Estimated tip
                                    </BringzzText>
                                </span>
                            </div>
                            <span className="font-bold">XX€</span>
                        </div>
                    </>
                )}

                {USER_ROLES.PHARMACY_ROLE == user.userRole &&
                    <>
                        <div className='flex space-x-4 border-b py-2'>
                            <div>
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconBox9'
                                    size='22'
                                    className='p-3 rounded-full' />
                            </div>
                            <div className='flex w-full'>
                                <div>
                                    <BringzzText tag='h3'>Order Type</BringzzText>
                                    <BringzzText tag='h4' className={"font-normal"}>{getOrderTypeString(orderDetails.type)}</BringzzText>
                                </div>
                            </div>
                        </div>
                        <div className='flex space-x-4 border-b py-2'>
                            <div>
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconFileName'
                                    size='22'
                                    className='px-3 py-2' />
                            </div>
                            <div className='flex w-full justify-between opacity-35'>
                                <div className='flex items-center'>
                                    <BringzzText tag='h3'>Prescription</BringzzText>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconEye'
                                        size='22' />
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconBarcode'
                                        size='22' />
                                </div>
                            </div>
                        </div>

                        <BringzzOrderCardSmallAccordion defaultExpanded={true} totalPrice={orderDetails.totalPrice} products={orderDetails.products} />
                    </>
                }
            </div>}

        </BringzzPageContainer >
    );
};

export default OrderViewPage;
