import React, { useState } from 'react';
import { BringzzButton, BringzzCheckbox, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import useRequest from 'hooks/useRequest';

import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import classNames from 'classnames';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConsumerLocationPage = () => {
    const { backPress } = useNavigation();
    const { navigate } = useNavigation();
    const { user } = useAuth();
    const { sendRequest } = useRequest();

    const [formValues, setFormValues] = useState({
        streetName: '',
        houseNumber: '',
        entrance: '',
        floor: '',
        door: '',
        postalCode: '',
        cityName: '',
        note: '',
    });
    const [country, setCountry] = useState({
        name: 'Austria',
        code: 'AT',
    });

    const [selectedLabel, setSelectedLabel] = useState(null);

    const labelOptions = [
        { id: 'home', icon: 'IconHome', name: 'Home' },
        { id: 'work', icon: 'IconBriefcase', name: 'Work' },
        { id: 'partner', icon: 'IconHeart', name: 'Partner' },
        { id: 'other', icon: 'IconMapMarker', name: 'Other' },
      ];

    const addNewAddress = async () => {
        try {
            const addressData = {
                addressData: {
                    streetName: formValues.streetName || null,
                    houseNumber: formValues.houseNumber || null,
                    belongsTo: user?.integrationId || null,
                    cityName: formValues.cityName || null,
                    postalCode: formValues.postalCode || null,
                    region: formValues.region || null,
                    countryName: formValues.countryName || country.name || null,
                    entrance: formValues.entrance || null,
                    floor: formValues.floor || null,
                    door: formValues.door || null,
                    note: formValues.note || null,
                    addressLable: selectedLabel || null,
                    addressIcon: labelOptions.find(label => label.id === selectedLabel)?.icon || null,
                }
            };
            const response = await sendRequest({
                method: 'POST',
                url: '/module/address/setAddress',
                data: addressData,
            });

            if (response.status === 'success') {
                navigate('/register/notification');
            }
        } catch (error) {
            console.error('Failed to add new address', error);
        }
    };

    const handleSave = () => {
        addNewAddress();
    };

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Location"
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={handleSave}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Add Your Address
                                </BringzzText>
                            </div>
                            <div className='mt-6'>
                                <div className='space-y-6 mt-4'>
                                    <div className='flex items-end w-full space-x-2'>
                                        <div className="flex-grow">
                                            <BringzzInput
                                                label="Street name and house number"
                                                placeholder="Street name and house number"
                                                icon={{
                                                    right: (
                                                        <BringzzIcon
                                                            icon='IconMagnifier'
                                                            folder='LineIcons'
                                                            size='16'
                                                            className='text-midnight-blue'
                                                        />
                                                    )
                                                }}
                                                className="w-full"
                                                value={formValues.streetName}
                                                onChange={(e) => setFormValues({ ...formValues, streetName: e.target.value })}
                                            />
                                        </div>
                                        <BringzzInput
                                            className='w-8'
                                            placeholder='#'
                                            value={formValues.houseNumber}
                                            onChange={(e) => setFormValues({ ...formValues, houseNumber: e.target.value })}
                                        />
                                    </div>

                                    <div className='flex gap-2'>
                                        <BringzzInput
                                            label="Entrance"
                                            placeholder="A"
                                            value={formValues.entrance}
                                            onChange={(e) => setFormValues({ ...formValues, entrance: e.target.value })}
                                        />
                                        <BringzzInput
                                            label="Floor"
                                            placeholder="4"
                                            value={formValues.floor}
                                            onChange={(e) => setFormValues({ ...formValues, floor: e.target.value })}
                                        />
                                        <BringzzInput
                                            label="Door"
                                            placeholder="10"
                                            value={formValues.door}
                                            onChange={(e) => setFormValues({ ...formValues, door: e.target.value })}
                                        />
                                    </div>
                                    <div className='flex gap-2'>
                                        <BringzzCountryDropdown
                                            mode="flag"
                                            label="Country"
                                            onCountryChange={(value) => setFormValues({ ...formValues, countryName: value })}
                                            className="flex-1"
                                        />
                                        <BringzzInput
                                            label="Postal code"
                                            placeholder="1010"
                                            className="flex-1"
                                            value={formValues.postalCode}
                                            onChange={(e) => setFormValues({ ...formValues, postalCode: e.target.value })}
                                        />
                                        <BringzzInput
                                            label="City"
                                            placeholder="Wien"
                                            className="flex-1"
                                            value={formValues.cityName}
                                            onChange={(e) => setFormValues({ ...formValues, cityName: e.target.value })}
                                        />
                                    </div>
                                    <div className='space-y-2'>
                                        <BringzzText tag="h4">Address labels (opt)</BringzzText>
                                        <div className='flex space-x-4'>
                                            {labelOptions.map((label) => (
                                                <div className="text-center space-y-1" key={label.id}>
                                                    <BringzzIcon
                                                        size='22'
                                                        icon={label.icon}
                                                        folder='LineIcons'
                                                        className={classNames(
                                                            "cursor-pointer flex items-center justify-center h-10 w-10 rounded-full",
                                                            selectedLabel === label.id ? "bg-magic-lilac" : "bg-gray-100"
                                                        )}
                                                        onClick={() => setSelectedLabel(label.id)}
                                                    />
                                                    <BringzzText className="font-normal text-xs">{label.id}</BringzzText>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <BringzzCheckbox label='Use the address as billing address' />
                                    </div>
                                    <div>
                                        <BringzzInput
                                            label='Note'
                                            type='textarea'
                                            placeholder='e.g. please call in case you cannot reach me at the door'
                                            value={formValues.note}
                                            onChange={(e) => setFormValues({ ...formValues, note: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ConsumerLocationPage;
