import { Wrapper, Status } from "@googlemaps/react-wrapper";
import React, { useEffect, useRef, useState } from "react";

const BringzzReadOnlyMap = ({ startLocation, endLocation }) => {
  const BasicMap = () => {
    const ref = useRef();
    const [map, setMap] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (!ref.current) return;

      // Create map instance with disabled controls
      const map = new window.google.maps.Map(ref.current, {
        gestureHandling: "none", // Prevents all map movements
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      });

      // Create DirectionsService for routing
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: false,
      });

      // Calculate route
      directionsService.route(
        {
          origin: startLocation,
          destination: endLocation,
          travelMode: window.google.maps.TravelMode.BICYCLING,
        },
        (result, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(result);
            // Fit map to route bounds
            const bounds = new window.google.maps.LatLngBounds();
            result.routes[0].legs[0].steps.forEach((step) => {
              bounds.extend(step.start_location);
              bounds.extend(step.end_location);
            });
            map.fitBounds(bounds);
            setError(null);
          } else {
            setError("Could not calculate bike route between these locations");
          }
        }
      );

      setMap(map);
    }, [startLocation, endLocation]);

    return (
      <>
        <div ref={ref} className="w-full min-h-[250px] h-full relative" />
        {error && (
          <div className="absolute top-4 left-1/2 -translate-x-1/2 bg-red-100 text-red-700 px-4 py-2 rounded">
            {error}
          </div>
        )}
      </>
    );
  };

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <span>Loading map...</span>;
      case Status.FAILURE:
        return <span>Error loading map</span>;
      case Status.SUCCESS:
        return <BasicMap />;
    }
  };

  return (
    <Wrapper
      apiKey={"AIzaSyAtms-7qsst_vD187mM3n6QVRNGrk1xC_4"}
      render={render}
    />
  );
};

export default BringzzReadOnlyMap;
