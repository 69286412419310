import React, { useState } from "react";
import BringzzIcon from "../BringzzIcon";
import PropTypes from "prop-types";
import BringzzText from "../BringzzText";

/**
 * @typedef BringzzAccordionProps
 * @property {Function} [onChange] - Function called when the accordion's state changes.
 * @property {boolean} [defaultValue] - Default expanded/collapsed state of the accordion.
 * @property {React.ReactNode} title - Title content of the accordion.
 * @property {{ expanded: React.ReactNode, closed: React.ReactNode }} [icon] - Custom icons for expanded and closed states.
 * @property {React.ReactNode} [children] - Content to be displayed inside the accordion when expanded.
 * @property {string} [className] - Additional CSS classes to apply to the accordion.
 */

/**
 * Accordion component to toggle visibility of content.
 * @param {BringzzAccordionProps} props - The component props.
 * @returns {JSX.Element} - JSX for the accordion component.
 */
export default function BringzzAccordion({
  onChange,
  defaultValue = false,
  title,
  subtitle,
  icon,
  titleIcon,
  children,
  className,
  separated = true,
  ...props
}) {
  const [isExpanded, setIsExpanded] = useState(defaultValue);

  const DefaultExpand = () => (
    <BringzzIcon icon="IconChevronUp" folder="LineIcons" size="16" />
  );
  const DefaultClosed = () => (
    <BringzzIcon icon="IconChevronDown" folder="LineIcons" size="16" />
  );

  const handleToggle = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  const currentIcon = isExpanded
    ? (icon && icon.expanded) || <DefaultExpand />
    : (icon && icon.closed) || <DefaultClosed />;

  return (
    <div {...props} className={className}>
      <div
        className=" cursor-pointer  text-midnight-blue"
      >
        <div className="flex items-start  justify-between py-3" onClick={handleToggle}>
          <div className="flex items-start justify-center gap-4">
            {titleIcon && <div>{titleIcon}</div>}
            <div>
              <BringzzText tag="h3">{title}</BringzzText>
              {subtitle && <div> {subtitle} </div>}
            </div>
          </div>
          <div>{currentIcon}</div>
        </div>
        <div
          className={`transition duration-300 ease-in-out overflow-hidden ${separated ? " border-b" : ""} ${isExpanded ? "max-h-96  pb-2" : "max-h-0"
            }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

BringzzAccordion.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool,
  title: PropTypes.node.isRequired,
  icon: PropTypes.shape({
    expanded: PropTypes.node,
    closed: PropTypes.node,
  }),
  children: PropTypes.node,
};
