import React, { useState, useEffect } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzInput,
	BringzzCountryDropdown,
	BringzzPageContainer,
	BringzzIcon,
	BringzzImage,
	BringzzText,
	BringzzButton
} from "@bringzz/components";
import {
	useDebounce
} from "@bringzz/hooks";
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

const AuthPage = () => {
	const { navigate } = useNavigation();
	const { setUser, loginWithToken, loginWithFacebook } = useAuth();
	const [tempUser, setTempUser] = useState(null)
	const { backPress } = useNavigation();
	const [country, setCountry] = useState({
		name: 'Austria',
		dial_code: '+43',
		code: 'AT'
	});

	const [isValidInput, setIsValidInput] = useState(false)
	const [inputValue, setInputValue] = useState('');
	const debouncedInputValue = useDebounce(inputValue, 500);

	const { sendRequest, data, error, loading } = useRequest();

	// Load Facebook SDK
	useEffect(() => {
		window.fbAsyncInit = function () {
			window.FB.init({
				// eslint-disable-next-line no-undef
				appId: process.env.REACT_APP_SSO_FACEBOOK_ID,
				cookie: true,
				xfbml: true,
				version: 'v15.0' // Use the latest version available
			});
			window.FB.AppEvents.logPageView();
		};

		// Load the SDK script
		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}, []);

	useEffect(() => {
		const phoneRegex = /^\d{5,}$/;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		let abortController = new AbortController();

		if (debouncedInputValue.trim() !== '') {
			const isPhone = phoneRegex.test(debouncedInputValue);
			const isEmail = emailRegex.test(debouncedInputValue);

			if (isPhone || isEmail) {
				const fetchData = async () => {
					const method = isPhone ? "phone" : "email";

					try {
						const response = await sendRequest({
							method: 'POST',
							url: `/module/user/authValidate`,
							data: {
								inputValue: debouncedInputValue,
								method,
								countryPart: country.dial_code.substring(1)
							},
							signal: abortController.signal
						});

						if (response.data) {
							console.log('Setting user:', response.data);
							setTempUser(response.data);
							setUser(response.data);
							console.log('User set successfully');
						} else {
							console.log('No valid user data returned, clearing user');
							setUser(null);
						}
					} catch (error) {
						if (error.name === 'AbortError') {
							console.log('Request was cancelled');
						} else {
							console.error('Error in fetchData:', error);
							setUser(null);
						}
					}
				};

				fetchData();

				return () => {
					abortController.abort();
					console.log('Aborting previous request');
				};
			}
		}
	}, [debouncedInputValue, sendRequest, country.dial_code]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');
		const state = urlParams.get('state');

		if (code && state) {
			handleGoogleCallback(code, state);
		}
	}, []);

	const handleGoogleCallback = async (code, state) => {
		try {
			const response = await sendRequest({
				method: 'POST',
				url: `/module/user/googleAuthCallback`,
				data: { code, state }
			});

			if (response.data)
				loginWithToken(response.data.token, response.data)
			else
				alert('must sign up first')
		} catch (error) {
			console.error('Error during Google login callback', error);
		}
	};

	const googleLogin = async () => {
		await sendRequest({
			method: 'GET',
			url: `/module/user/getLoginUrl`,
		}).then((response) => {
			if (response.status === "success" && response.data.loginUrl) {
				window.location.href = response.data.loginUrl;
			} else {
				console.error('Error: Invalid response format', response);
			}
		}).catch((error) => {
			console.error(error);
		})
	}

	const handleNavigation = () => {
		const method = new RegExp('^\\d{5,}$').test(inputValue) ? "phone" : "email"
		const value = method == "phone" ? `${country.dial_code}%20${inputValue}` : inputValue
		const path = tempUser ? "login" : "register"
		let redirectUrl = `/${path}?method=${method}&value=${value}`
		if (tempUser) redirectUrl += `&firstname=${tempUser.firstName}`

		console.log('______ AUTH _____');
		console.log(tempUser);
		if (!tempUser) {
			navigate(redirectUrl);
		} else if (tempUser.isPreuser) {
			console.log("______ TEMP ______");
			const id = tempUser.id
			navigate(`/register/otp?method=${method}&userId=${id}&value=${value}&redirect=/register/consumer/profile`, false)
		} else {

			navigate(redirectUrl);
		}
	}

	const clientId = 'de.mysucurema.bringzzdev.service'; // Replace with your actual Service ID
	// eslint-disable-next-line no-undef
	const redirectUri = `${process.env.REACT_APP_USE_BACKEND}/module/user/redirectWithCode`;
	const scope = 'email name'; // Scopes you're requesting
	const state = 'apple#tokenLogin'; // Any custom state you want to pass
	const responseMode = 'form_post'; // Response mode (can also be 'query')
	const responseType = 'code' // Response type

	const handleAppleSignIn = () => {
		// Construct the authorization URL
		const authorizationUrl = `https://appleid.apple.com/auth/authorize?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}&state=${encodeURIComponent(state)}&response_mode=${encodeURIComponent(responseMode)}&response_code=${responseType}`;

		window.location.href = authorizationUrl;
	};

	return (
		<BringzzPageContainer
			className='bg-magic-lilac'
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconClose'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>

				)
			}}
			footer={
				<div className='pb-8 px-6'>
					<div className='flex flex-col px-4'>
						<BringzzText
							className='text-mignight-blue font-normal text-xs'
						>
							By continuing, <br /> I agree to Bringzz’s{' '}
							<span className=' font-medium underline'>
								Privacy Policy
							</span>{' '}
							and{' '}
							<span className='font-medium  underline'>Terms of use</span>
						</BringzzText>
					</div>
					<div className='flex justify-center mt-6'>
						<BringzzButton
							size='lg'
							className='bg-midnight-blue w-full flex justify-center text-white'
							onClick={() => {
								handleNavigation();
							}}
							disabled={!isValidInput || loading}
						>
							{!isValidInput && "Continue"}
							{isValidInput &&
								(tempUser ? "Sign-in" : "Join-us")
							}
						</BringzzButton>
					</div>
				</div>
			}
			authenticated={false}
		>
			<div className='flex flex-col h-full w-full'>
				<div className='flex flex-col h-1/2 px-4 justify-between'>
					<div className='flex grow justify-center items-center'>
						<BringzzImage className="h-14" src="BringzzLogo.png" />
					</div>
					<div className='flex mt-6'>
						<BringzzInput
							containerClassname="w-full"
							className="!pl-16"
							type='text'
							placeholder="Phone Number or Email"
							onChange={(e, isValid, newValue) => {
								setIsValidInput(isValid);
								setInputValue(newValue);
							}}
							pattern='^(?:\+?\d{1,3}[-\s.]?\d{3,}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+)$'
							icon={{
								left: <BringzzCountryDropdown
									className="!rounded-r-none"
									mode={'modal'}
									onCountryChange={result => {
										setCountry(result);
									}}
								/>
							}}>
						</BringzzInput>
					</div>
				</div>
				<div className='h-1/2 px-6 w-full'>
					<div className='flex items-center gap-x-4 mt-6'>
						<div className='grow bg-sand h-[1px]'></div>
						<div>
							<BringzzText tag='h4' className='!text-sand font-normal'>
								or continue with
							</BringzzText>
						</div>
						<div className='grow bg-sand h-[1px]'></div>
					</div>
					<div className='flex justify-center space-x-6 pt-12'>
						<div className="flex justify-center items-center w-14 h-14 border border-midnight-blue cursor-pointer rounded-full overflow-hidden"
							onClick={handleAppleSignIn}
						>
							<BringzzImage className='h-8' src="/icons/apple.png" />
						</div>
						<div className="flex justify-center cursor-pointer items-center w-14 h-14 border border-midnight-blue rounded-full overflow-hidden"
							onClick={async () => {
								await loginWithFacebook();
							}}
						>
							<BringzzImage className='h-8' src="/icons/facebook.png" />
						</div>
						<div className="flex justify-center cursor-pointer items-center w-14 h-14 border border-midnight-blue rounded-full overflow-hidden"
							onClick={googleLogin}
						>
							<BringzzImage className='h-8' src="/icons/google.png" />
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer >
	);
};

export default AuthPage;
