import React from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText } from "@bringzz/components";
import useUser from 'hooks/useUser';
import { useAuth } from 'context/AuthContext';

const SettingsPage = (props) => {
    const { backPress, navigate } = useNavigation();
    const { getUser } = useUser();
    const user = getUser();

    // switch (bringzzRole) {
    //     case 1: 'pharmacist',
    //     case 4: 'shopper',
    //     case 5: 'customer'
    //   }

    const items = [
        {
            left: {
                icon: "IconUserAlt1",
                folder: "LineIcons"
            },
            label: "My Account",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/settings/account",
            roles: []
        },
        {
            left: {
                icon: "IconPlus",
                folder: "LineIcons"
            },
            label: "My Pharmacy",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/settings/pharmacy",
            roles: [1]
        },
        {
            left: {
                icon: "IconBox4",
                folder: "LineIcons"
            },
            label: "Orders",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/settings/pharmacy/orders",
            roles: [1]
        },
        {
            left: {
                icon: "IconBox4",
                folder: "LineIcons"
            },
            label: "Orders",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/consumer/orders",
            roles: [5]
        },
        {
            left: {
                icon: "IconFiles",
                folder: "LineIcons"
            },
            label: "Posts",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "settings/posts",
            roles: [1]
        },
        {
            left: {
                icon: "IconCup",
                folder: "LineIcons"
            },
            label: "Riders Club",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "",
            roles: [4]
        },
        {
            left: {
                icon: "IconBookmark",
                folder: "LineIcons"
            },
            label: "Bookmarks",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "settings/bookmarks",
            roles: []
        },
        {
            left: {
                icon: "IconMapMarker",
                folder: "LineIcons"
            },
            label: "Addresses",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/address/",
            roles: [5, 4]
        },
        {
            left: {
                icon: "IconCreditCards",
                folder: "LineIcons"
            },
            label: "Payment methods",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "",
            roles: []
        },
        {
            left: {
                icon: "IconSpeechBubble16",
                folder: "LineIcons"
            },
            label: "Chats",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/settings/chats",
            roles: [1, 5]
        },
        {
            left: {
                icon: "IconQuestionCircle",
                folder: "LineIcons"
            },
            label: "FAQ & Support",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/faq",
            roles: []
        },
        {
            left: {
                icon: "IconCog",
                folder: "LineIcons"
            },
            label: "App Settings",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/settings/app",
            roles: []
        },
        {
            label: "About Bringzz",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "",
            roles: [1, 4]
        },
        {
            label: "Terms of service",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/terms",
            roles: [1, 4]
        },
        {
            label: "Privacy Policy",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "",
            roles: [1, 4]
        },
        {
            label: "Imprint",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "",
            roles: [1, 4]
        },
    ]

    // Filter items based on user role
    const filteredItems = items.filter(item => !item.roles.length || item.roles.includes(user.userRole));

    const { logout } = useAuth()

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>SETTINGS</BringzzText>
            }}>

            <div className='p-4 divide-y divide-solid'>
                {filteredItems.map((item, index) => {
                    return <div key={index} className='flex items-center justify-between py-4 cursor-pointer' onClick={() => item.path && navigate(item.path)}>
                        <div className='flex space-x-3'>
                            {item.left && <BringzzIcon
                                size="22"
                                icon={item.left.icon}
                                folder={item.left.folder}
                            />}
                            <BringzzText tag="h4" className="font-gt-maru">{item.label}</BringzzText>
                        </div>
                        {item.right && <BringzzIcon
                            size="18"
                            icon={item.right.icon}
                            folder={item.right.folder}
                        />}
                    </div>
                })}
                <div className='flex items-center justify-between py-4 cursor-pointer' onClick={async () => {
                    await logout()
                    navigate('/')
                }}>
                    <div className='flex space-x-3'>
                        <BringzzText tag="h4" className="font-gt-maru underline">Signout</BringzzText>
                    </div>
                </div>
            </div>
        </BringzzPageContainer>

    )
}

export default SettingsPage;
