import React, { useState, useEffect } from 'react';
import { BringzzAccordion, BringzzButton, BringzzInput, BringzzPromoBanner, BringzzStarRating, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct,
    BringzzProgressBar
} from "@bringzz/components";
import BikeImage from 'assets/images/Bike.png'
import RocketImage from 'assets/images/Rocket.png'
import BringzzFeedback from 'components/BringzzFeedback';
import { useOrder } from 'context/OrderContext';

const MatchmakingPickupCompletedPage = () => {
    const { backPress, navigate } = useNavigation();
    const [activeStep, setActiveStep] = useState(8)
    const [selectedTip, setSelectedTip] = useState(null);
    const { orderDetails, resetOrderContext } = useOrder();
    const selectTip = amount => {
        setSelectedTip(amount); // Update the selected tip
    };

    useEffect(() => {
        if (orderDetails) {
            resetOrderContext();
        }
    }, [orderDetails, resetOrderContext]);

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={() => navigate('/home')}
                    />
                ),
            }}
            authenticated={true}
            footerHover={true}
            footer={
                <div className='p-6 space-y-4 text-center'>
                    <BringzzText tag='h4' className=''>You can also thank him with a tip:</BringzzText>
                    <div className='flex items-center justify-center gap-x-3'>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 1 ? 'bg-magic-lilac' : 'bg-sand '}`}
                            onClick={() => selectTip(1)}
                        >
                            €1
                        </BringzzButton>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 3 ? 'bg-magic-lilac ' : 'bg-sand '}`}
                            onClick={() => selectTip(3)}
                        >
                            €3
                        </BringzzButton>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 5 ? 'bg-magic-lilac' : 'bg-sand '}`}
                            onClick={() => selectTip(5)}
                        >
                            €5
                        </BringzzButton>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 'Other' ? 'bg-magic-lilac' : 'bg-sand '}`}
                            onClick={e => {
                                e.stopPropagation();
                                selectTip('Other');
                            }}
                        >
                            Other
                        </BringzzButton>
                    </div>
                    <BringzzButton size='lg' className='bg-magic-lilac w-full flex justify-center' onClick={() => navigate('/home')}>Submit</BringzzButton>
                </div>
            }
        >

            <div className='px-6'>
                <div className='py-8 space-y-2 flex flex-col items-center'>
                    <BringzzText tag='h1' className='text-center'>Thank you!</BringzzText>
                    <BringzzText tag='h4' className='w-64 text-center font-normal'>You have received your package
                        lorem ipsum dolor sit amet est.</BringzzText>
                </div>

                <div className='py-4 space-y-6 w-full flex flex-col items-center'>
                    <BringzzIcon folder='CustomIcons' icon='IconMountain' size='65' className='text-gray-400' />
                    <BringzzText tag='h2' className='font-normal'>How was Dennis’ service?</BringzzText>
                    <BringzzText tag='h5' className='w-64 text-center font-normal'>Shoppers always appreciate your feedback and generosity.</BringzzText>
                    <BringzzFeedback></BringzzFeedback>
                </div>

                <div className='px-6'>
                    <BringzzInput type='textarea' className='border' placeholder='Give Dennis some personal feedback...'></BringzzInput>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default MatchmakingPickupCompletedPage;