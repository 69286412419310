import React, { useState, useEffect } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
  BringzzPageContainer,
  BringzzCountryDropdown,
  BringzzButton,
  BringzzIcon,
  BringzzText,
  BringzzInput
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const EditPharmacyAddress = () => {
  const { backPress, navigate } = useNavigation();
  const { sendRequest, loading } = useRequest();
  const { getUser } = useUser();
  const user = getUser();
  const [pharmacyAddressStreetName, setPharmacyAddressStreetName] = useState('');
  const [pharmacyAddressHouseNumber, setPharmacyAddressHouseNumber] = useState('');
  const [pharmacyAddressEntrance, setPharmacyAddressEntrance] = useState('');
  const [pharmacyAddressFloor, setPharmacyAddressFloor] = useState('');
  const [pharmacyAddressDoor, setPharmacyAddressDoor] = useState('');
  const [pharmacyAddressPostalCode, setPharmacyAddressPostalCode] = useState('');
  const [pharmacyAddressCity, setPharmacyAddressCity] = useState('');
  const [pharmacyAddressCountry, setPharmacyAddressCountry] = useState('');
  const [integrationId, setIntegrationId] = useState('');

  useEffect(() => {
    getAddress();
  }, []);

  const getAddress = async () => {
    try {
      const response = await sendRequest({
        method: 'GET',
        url: '/module/address/getAddress',
        
      });

      if (response.status === 'success') {
        const address = response.data[0];
        setPharmacyAddressStreetName(address.streetName);
        setPharmacyAddressHouseNumber(address.houseNumber);
        setPharmacyAddressEntrance(address.entrance);
        setPharmacyAddressFloor(address.floor);
        setPharmacyAddressDoor(address.door);
        setPharmacyAddressPostalCode(address.postalCode);
        setPharmacyAddressCity(address.cityName);
        setPharmacyAddressCountry(address.countryName);
        setIntegrationId(address.integrationId);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  const updateAddress = async () => {
    try {
      const response = await sendRequest({
        method: 'PUT',
        url: '/module/address/updateAddress',
        data: {
          integrationId: integrationId,
          addressData: {
            streetName: pharmacyAddressStreetName,
            houseNumber: pharmacyAddressHouseNumber,
            cityName: pharmacyAddressCity,
            postalCode: pharmacyAddressPostalCode,
            countryName: pharmacyAddressCountry,
          },
        },
      });

      if (response.status === 'success') {
        navigate('/settings/pharmacy/info', false);
      }
    } catch (error) {
      console.error('Error updating address:', error);
    }
  };

  return (
    <BringzzPageContainer
      header={{
        left: (
          <BringzzIcon
            size='22'
            icon='IconChevronLeft'
            folder='LineIcons'
            className='cursor-pointer'
            onClick={backPress}
          />
        ),
        title: <BringzzText tag='h2'>Edit address</BringzzText>
      }}
      footer={
        <div className='pb-28 px-6'>
          <BringzzButton
            size='lg'
            className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
            onClick={updateAddress}
            disabled={
              loading ||
              (!pharmacyAddressStreetName &&
                !pharmacyAddressHouseNumber &&
                !pharmacyAddressPostalCode &&
                !pharmacyAddressCity &&
                !pharmacyAddressCountry &&
                !pharmacyAddressEntrance &&
                !pharmacyAddressFloor &&
                !pharmacyAddressDoor)
            }
          >
            Save
          </BringzzButton>
        </div>
      }
    >
      <div className='p-6 space-y-4'>
        <div className='flex flex-1 gap-3'>
          <BringzzInput
            onChange={(e, isValid, newValue) => setPharmacyAddressStreetName(newValue)}
            defaultValue={pharmacyAddressStreetName}
            id='streename'
            label='Street Name'
            containerClassname='w-full'
          />
          <BringzzInput
            onChange={(e, isValid, newValue) => setPharmacyAddressHouseNumber(newValue)}
            defaultValue={pharmacyAddressHouseNumber}
            id='housenumber'
            label='House Number'
            containerClassname='w-full'
          />
        </div>
        <div className='flex flex-1 gap-3'>
          <BringzzInput
            onChange={(e, isValid, newValue) => setPharmacyAddressEntrance(newValue)}
            defaultValue={pharmacyAddressEntrance}
            id='entrance'
            label='Entrance (opt.)'
            containerClassname='w-full'
            placeholder='Entrance'
          />
          <BringzzInput
            onChange={(e, isValid, newValue) => setPharmacyAddressFloor(newValue)}
            defaultValue={pharmacyAddressFloor}
            id='floor'
            label='Floor (opt.)'
            type='number'
            containerClassname='w-full'
            placeholder='Floor'
          />
          <BringzzInput
			onChange={(e, isValid, newValue) => setPharmacyAddressDoor(newValue)}
			defaultValue={pharmacyAddressDoor}
			id="door"
			label="Door (opt.)"
			type="text"
			containerClassname="w-full"
			placeholder="Door"
			/>
        </div>
        <div className='flex flex-1 gap-3'>
          <BringzzInput
            onChange={(e, isValid, newValue) => setPharmacyAddressPostalCode(newValue)}
            defaultValue={pharmacyAddressPostalCode}
            id='postalcode'
            label='Postal Code'
            containerClassname='w-full'
          />
          <BringzzInput
            onChange={(e, isValid, newValue) => setPharmacyAddressCity(newValue)}
            defaultValue={pharmacyAddressCity}
            id='city'
            label='City'
            containerClassname='w-full'
          />
        </div>

        <div className=''>
          <BringzzText tag='h4' className='font-bold'>
            Country
          </BringzzText>
          <BringzzCountryDropdown
            className='!rounded-r-none p-2 bg-[#F4F4F4]'
            mode={'flag-name'}
            onCountryChange={result => setPharmacyAddressCountry(result)}
          />
        </div>
      </div>
    </BringzzPageContainer>
  );
};

export default EditPharmacyAddress;
