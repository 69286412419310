import React, { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';
import { useNavigation } from '../../context/NavigationContext';
import {
    BringzzIcon,
    BringzzText,
    BringzzModal,
    BringzzPageContainer
} from '@bringzz/components';

const AddressPage = () => {
    const { user } = useAuth();
    const { sendRequest, loading } = useRequest();
    const [userAddress, setUserAddress] = useState([]);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [showOptionsId, setShowOptionsId] = useState(null);
    const { backPress, navigate } = useNavigation();
    const [modalOpen, setModalOpen] = useState(false);

    const fetchAddresses = async () => {
        if (!user || !user.integrationId) {
            console.log('User role not available');
            setUserAddress([]);
            return;
        }

        await sendRequest({
            method: 'GET',
            url: '/module/address/getAddress',
        })
            .then((response) => {
                if (response.status === 'success' && Array.isArray(response.data)) {
                    setUserAddress(response.data);
                } else {
                    setUserAddress([]);
                }
            })
            .catch((error) => {
                console.error('Request failed:', error);
                setUserAddress([]);
            });
    };

    useEffect(() => {
        if (user && user.integrationId) fetchAddresses();
    }, [user]);
    const handleDeleteAddress = async () => {
        if (!selectedAddressId) return;

        try {
            const addressToDelete = userAddress.find(address => address.id === selectedAddressId);
            if (!addressToDelete) {
                console.error("Address not found");
                return;
            }
            const { integrationId } = addressToDelete;

            const response = await sendRequest({
                method: 'POST',
                url: '/module/address/deleteAddressByIntegrationId',
                data: {
                    integrationId: integrationId,
                }
            });

            if (response.status === 'success') {
                setUserAddress(userAddress.filter((address) => address.id !== selectedAddressId));
                setModalOpen(false);
            } else {
                console.error('Failed to delete the address');
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const isEdit = searchParams.has('edit');
    const isNew = searchParams.get('new') === 'true';

    useEffect(() => {
        if (isEdit) {
            const addressId = searchParams.get('edit');
        }
    }, [isEdit, searchParams]);

    const handleBackPress = () => {
        window.history.back();
    };

    // TODO: Add footer in the correct place
    return (
        <BringzzPageContainer
            title={isNew ? 'Add New Address' : isEdit ? 'Edit Address' : 'Addresses'}
        >
            <div className="flex flex-col h-screen bg-white">
                <div className="flex items-center px-4 py-4 bg-white shadow-sm">
                    <div className="cursor-pointer" onClick={() => navigate(-1)}>
                        <BringzzIcon
                            size='22'
                            icon='IconChevronLeft'
                            folder='LineIcons'
                            className='cursor-pointer'
                            onClick={handleBackPress}
                        />
                    </div>
                    <BringzzText tag='h4'>{isNew ? 'Add New Address' : isEdit ? 'Edit Address' : 'Addresses'}</BringzzText>
                </div>
                <div className="flex-grow px-4 pt-6 space-y-4">
                    {userAddress.map((address) => (
                        <div key={address.id} className="bg-white">
                            <div className="flex items-center justify-between px-4 py-6 relative">
                                <div className="flex items-center gap-4">
                                    <div>
                                        <BringzzIcon
                                            size='22'
                                            icon='IconMoshjidLocation'
                                            folder='LineIcons'
                                            className='cursor-pointer'
                                        />
                                    </div>
                                    <div>
                                        <p className="text-sm font-semibold text-[#1C1B1F]">
                                            {address.streetName}, {address.cityName}, {address.postalCode}
                                        </p>
                                        <p className="text-sm text-[#49454F]">
                                            {address.region && `${address.region}, `}{address.countryName}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-2">
                                    {showOptionsId === address.id ? (
                                        <div className="flex rounded-lg overflow-hidden">
                                            <div
                                                className="flex items-center justify-center w-10 h-10 bg-[#F3E8FF] cursor-pointer"
                                                onClick={() => navigate(`/settings/account/address?edit=${address.integrationId}`)}
                                            >
                                                <BringzzIcon
                                                    size='22'
                                                    icon='IconPencilCopy'
                                                    folder='LineIcons'
                                                    className='cursor-pointer'
                                                />
                                            </div>

                                            <div
                                                className="flex items-center justify-center w-10 h-10 bg-[#EADDFF] cursor-pointer"
                                                onClick={() => {
                                                    setSelectedAddressId(address.id);
                                                    setModalOpen(true);
                                                }}
                                            >
                                                <BringzzIcon
                                                    size='22'
                                                    icon='IconTrashCanCopy'
                                                    folder='LineIcons'
                                                    className='cursor-pointer'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowOptionsId(showOptionsId === address.id ? null : address.id)
                                            }
                                        >
                                            <BringzzIcon
                                                size='22'
                                                icon='IconPencilCopy'
                                                folder='LineIcons'
                                                className='cursor-pointer'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className="border-gray-300 my-4" />
                        </div>
                    ))}
                </div>

                <div className="px-4 pb-6">
                    <button
                        className="w-full py-4 text-sm font-medium text-white bg-[#0056D2] rounded-full"
                        onClick={() => navigate('/settings/account/address?new=true')}
                    >
                        <BringzzText
                            tag='h3'
                            className='text-normal text-white whitespace-pre-line truncate'
                        >
                            Add new address
                        </BringzzText>
                    </button>
                </div>

                {modalOpen && (
                    <BringzzModal
                        title={'Delete this address ?'}
                        description={`This address will be permanently deleted.`}
                        onCancel={() => setModalOpen(false)}
                        confirmButtonText='Delete'
                        onConfirm={handleDeleteAddress}
                    />
                )}
            </div>
        </BringzzPageContainer>
    );
};

export default AddressPage;
