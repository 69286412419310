import React from 'react';
import { BringzzIcon, BringzzText, BringzzImage } from "@bringzz/components";
import { useNavigation } from "context/NavigationContext";

const BringzzCustomerCardSmall = ({ customer }) => {
    const { navigate } = useNavigation();

    if (!customer) {
        return (
            <div className='flex space-x-4 border-b py-1 text-black/20'>
                <div>
                    <BringzzIcon
                        folder='HealthIcons'
                        icon='IconPharmacy'
                        size='22'
                        className='p-3 rounded-full' />
                </div>
                <div className='flex w-full items-center'>
                    <BringzzText tag='h3' className='text-black/20'>Customer</BringzzText>
                </div>
            </div>
        );
    }

    return (
        <div className='flex space-x-4 border-b py-2'>
            {customer.userPic ? (
                <div>
                    <BringzzImage
                        src={customer.userPic || "/images/1.jpg"}
                        width="36px"
                        height="36px"
                        className="rounded-full !h-[36px]"
                    ></BringzzImage>
                </div>
            ) : (
                <div>
                    <BringzzIcon
                        folder='LineIcons'
                        icon='IconUser'
                        size='22'
                        className='p-3 rounded-full' />
                </div>
            )}
            <div className='flex w-full justify-between'>
                <div>
                    <BringzzText tag='h3'>Customer</BringzzText>
                    <BringzzText tag='h4' className={"font-light"}>{customer.firstName || "customer_name"}</BringzzText>
                </div>
                <div className='flex items-center space-x-2'>
                    <BringzzIcon
                        folder='LineIcons'
                        icon='IconPhone'
                        size='16'
                        className='cursor-pointer'
                        onClick={() => {
                            window.open(`tel:${customer.phoneNumber}`, '_blank');
                        }} />
                    <BringzzIcon
                        folder='LineIcons'
                        icon='IconSupport'
                        size='21'
                        className='cursor-pointer'
                        onClick={() => {
                            // TODO: work on chat page
                            // navigate(`/chat/${pharmacy.integrationId}`);
                            navigate(`/settings/chats/new`);
                        }} />
                </div>
            </div>
        </div>
    );
};

export default BringzzCustomerCardSmall;