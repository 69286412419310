import React, { useState, useEffect } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzButton
} from '@bringzz/components';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
import { useNavigation } from 'context/NavigationContext';

function formatDate(dateString) {
    const [date, time] = dateString.split(' ');
    return date;
}

const PharmacyProductsView = () => {
    const { backPress, navigate } = useNavigation();
    const { getUser } = useUser();
    const user = getUser();
    const { sendRequest, error, loading } = useRequest();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchProductsData();
    }, []);

    const fetchProductsData = async () => {
        try {
            const response = await sendRequest({
                method: 'GET',
                url: '/module/pharmacyproduct/getPharmacyProducts'
            });

            if (response?.status === 'success' && Array.isArray(response.data)) {
                setProducts(response.data);
            } else {
                console.error('Invalid response format:', response);
                throw new Error('Invalid response format from server');
            }
        } catch (error) {
            console.error('Error fetching products data:', error);
        }
    };

    return (
        <BringzzPageContainer
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: <BringzzText tag='h2'>My Products</BringzzText>
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        // onClick={() => navigate('/settings/pharmacy/products/add')}
                        disabled={true}
                    >
                        Add product
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-4 divide-y divide-solid'>
                {loading ? (
                    <div className="flex justify-center py-4">
                        <BringzzText tag='h3'>Loading products...</BringzzText>
                    </div>
                ) : error ? (
                    <div className="flex justify-center py-4">
                        <BringzzText tag='h3' className="text-red-500">
                            {error?.message || 'Error loading products. Please try again.'}
                        </BringzzText>
                    </div>
                ) : products.length === 0 ? (
                    <div className="flex justify-center py-4">
                        <BringzzText tag='h3'>No products found</BringzzText>
                    </div>
                ) : (
                    products.map((product) => (
                        <div
                            key={product.id}
                            className='flex items-center justify-between py-4 px-2'
                        >
                            <div className='flex flex-col flex-1'>
                                <BringzzText tag='h3'>
                                    {product.productId_Dyn_Sort || 'product'}
                                </BringzzText>
                                <BringzzText tag='h3' className='text-normal text-black/50'>
                                    {product.price} EURO
                                </BringzzText>
                            </div>
                            <div className='flex items-center gap-4'>
                                <BringzzIcon
                                    size='18'
                                    onClick={() => navigate(`settings/pharmacy/products/edit?productId=${product.id}`)}
                                    icon={'IconPencil'}
                                    folder={'LineIcons'}
                                />
                                <BringzzIcon
                                    size='18'
                                    icon={'IconTrashCan'}
                                    folder={'LineIcons'}
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>
        </BringzzPageContainer>
    );
};

export default PharmacyProductsView; 