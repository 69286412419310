import { BringzzButton, BringzzIcon, BringzzBottomDrawer, BringzzInput, BringzzText, BringzzQrCode } from '@bringzz/components';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useOrder } from 'context/OrderContext';
import { userComponentAccess } from 'pages/CmsPage/helpers/user';
import useUser from 'hooks/useUser';
import { USER_ROLES } from 'utils/constants';

const CAMERA_MODES = {
    PICKUP_HANDOVER: 'pickup/handover',
    PICKUP_IDENTIFY: 'pickup/identify',
    PICKUP_CONFIRM: 'pickup/confirm',
    PICKUP_COLLECT: 'pickup/collect',
    PACKAGE_HANDOVER: 'package/handover',
    PACKAGE_IDENTIFY: 'package/identify',
    PACKAGE_CONFIRM: 'package/confirm',
    PACKAGE_COLLECT: 'package/collect',
    DELIVERY_HANDOVER: 'delivery/handover',
    DELIVERY_IDENTIFY: 'delivery/identify',
    DELIVERY_CONFIRM: 'delivery/confirm',
    DELIVERY_COLLECT: 'delivery/collect'
};

const CameraPage = () => {
    const videoRef = useRef(null);
    const { backPress, navigate } = useNavigation();
    const [failDrawerOpened, setFailDrawerOpened] = useState(false)
    const [idCode, setIdCode] = useState('')
    const { mode, type } = useParams(); // type: pickup/package/delivery, mode: collect/confirm/handover/identify
    const cameraMode = CAMERA_MODES[`${type.toUpperCase()}_${mode.toUpperCase()}`]
    console.log("cameraMode", cameraMode);

    const { sendRequest, loading } = useRequest()
    const { orderDetails, proceedToNextStep, activeStep } = useOrder()
    console.log("orderDetails", orderDetails);
    const { getUser } = useUser();
    const user = getUser();

    useEffect(() => {
        // Get access to the camera
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error("Error accessing the camera: ", err);
            }
        };

        startCamera();

        // Cleanup on component unmount
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                let stream = videoRef.current.srcObject;
                let tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
                videoRef.current.srcObject = null; // Clear the video element source
            }
        };
    }, []);

    const handleHandover = async () => {
        console.log("handleHandover");
        console.log({
            idCode: idCode,
            orderUUID: orderDetails.uuid || orderDetails.integrationId
        });

        await sendRequest({
            method: 'POST',
            url: `/module/order/authenticateParties`,
            data: {
                idCode: idCode,
                orderUUID: orderDetails.uuid || orderDetails.integrationId
            }
        }).then((response) => {
            console.log("response", response);
            if (response.status === 'success') {
                proceedToNextStep();
                if (user.userRole === USER_ROLES.PHARMACY_ROLE) {
                    navigate(`/matchmaking/package/6`)
                } else if (user.userRole === USER_ROLES.CONSUMER_ROLE) {
                    if (activeStep <= 5) {
                        navigate(`/matchmaking/pickup/5`)
                    } else {
                        navigate(`/matchmaking/pickup/11`)
                    }
                } else {
                    navigate(`/matchmaking/${type}/5`)
                }
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    const handleConfirm = async () => {
        await sendRequest({
            method: 'POST',
            url: `/module/order/verifyPackage`,
            data: {
                orderUUID: orderDetails.uuid || orderDetails.integrationId
            }
        }).then((response) => {
            console.log("response", response);
            if (response.status === 'success') {
                if (user.userRole === USER_ROLES.PHARMACY_ROLE) {
                    proceedToNextStep();
                    navigate(`/matchmaking/package/7`)
                } else if (user.userRole === USER_ROLES.SHOPPER_ROLE) {
                    proceedToNextStep();
                    navigate(`/matchmaking/delivery/9`)
                } else if (user.userRole === USER_ROLES.CONSUMER_ROLE) {
                    proceedToNextStep();
                    navigate(`/matchmaking/pickup/13`)
                } else {
                    console.error("camera page: user role not found");
                }
            }
        }).catch((error) => {
            console.error(error);
        })
    }
    const submitCode = async () => {
        if (cameraMode === CAMERA_MODES.PICKUP_HANDOVER || cameraMode === CAMERA_MODES.PACKAGE_IDENTIFY || cameraMode === CAMERA_MODES.PICKUP_IDENTIFY) {
            handleHandover()
        } else if (cameraMode === CAMERA_MODES.PACKAGE_CONFIRM || cameraMode === CAMERA_MODES.DELIVERY_COLLECT || cameraMode === CAMERA_MODES.DELIVERY_CONFIRM || cameraMode === CAMERA_MODES.PICKUP_CONFIRM) {
            handleConfirm()
        } else {
            console.error("camera page: camera mode not found");
        }
    }

    return (
        <div className="fixed inset-0">
            {mode !== "collect" && <video
                ref={videoRef}
                autoPlay
                className="w-full h-full object-cover"
            />}

            <div className='absolute top-32 text-center w-full px-8 '>
                {/* costumer */}
                {cameraMode === CAMERA_MODES.PICKUP_HANDOVER &&
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code on the shopper phone to verify that he belongs to the order.</BringzzText>
                }
                {/* pharmacist */}
                {cameraMode === CAMERA_MODES.PACKAGE_IDENTIFY && <div>
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code on the shopper phone to verify that he belongs to the order.</BringzzText>
                </div>
                }
                {/* pharmacist */}
                {cameraMode === CAMERA_MODES.PACKAGE_CONFIRM && <div>
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code on the order to confirm handover to the shopper</BringzzText>
                </div>
                }
                {/* shopper */}
                {cameraMode === CAMERA_MODES.DELIVERY_COLLECT && <div>
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code on the order to confirm pickup from pharmacy</BringzzText>
                </div>
                }
                {cameraMode === CAMERA_MODES.PICKUP_IDENTIFY && <div>
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code to identify the shopper with your package</BringzzText>
                </div>
                }
                {cameraMode === CAMERA_MODES.DELIVERY_CONFIRM && <div>
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code to to confirm that you have delivered the order.</BringzzText>

                </div>
                }
                {cameraMode === CAMERA_MODES.PICKUP_CONFIRM && <div>
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code on your order bag to confirm that you have received the correct order.</BringzzText>
                </div>
                }

            </div>

            {/* Overlay square in the center */}
            {cameraMode === CAMERA_MODES.COLLECT_CONFIRM && <div className='flex justify-center items-center w-full h-full'><BringzzQrCode value={"123456798"} /></div>}
            {cameraMode !== CAMERA_MODES.COLLECT_CONFIRM && <div
                className="absolute top-1/2 left-1/2 w-64 h-64 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none grid grid-cols-3 grid-rows-3"
            >
                {/* Top-left corner */}
                <div className="border-t-2 border-l-2 border-white bg-opacity-50 col-start-1 row-start-1" />

                {/* Top-right corner */}
                <div className="border-t-2 border-r-2 border-white bg-opacity-50 col-start-3 row-start-1" />

                {/* Bottom-left corner */}
                <div className="border-b-2 border-l-2 border-white bg-opacity-50 col-start-1 row-start-3" />

                {/* Bottom-right corner */}
                <div className="border-b-2 border-r-2 border-white bg-opacity-50 col-start-3 row-start-3" />
            </div>}

            {/* Top-left content area */}
            <div className="absolute top-0 left-0 p-5">
                <BringzzIcon
                    size="16"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer bg-white rounded-full p-3"
                    onClick={backPress}
                />
            </div>

            {/* Bottom-center footer area */}
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-6">
                <BringzzButton onClick={() => setFailDrawerOpened(true)} size='md' className='text-white border border-white'>QR-Code not working?</BringzzButton>
            </div>

            <BringzzBottomDrawer
                isOpen={failDrawerOpened}
                close={() => setFailDrawerOpened(false)}
                title='Enter ID-Code'
            >
                <div className='px-4 py-6'>
                    <div>
                        <BringzzInput label='Id-Code' placeholder={'ID-Code'} onChange={(e, newIsValid, newValue) => {
                            setIdCode(newValue)
                        }}></BringzzInput>
                    </div>
                </div>

                <div className='text-center mb-12 px-4'>
                    <BringzzButton
                        className='bg-magic-lilac w-full flex justify-center'
                        onClick={() => {
                            setFailDrawerOpened(false)
                            submitCode()
                        }}
                        disabled={idCode.length == 0 || loading}
                    >
                        Apply
                    </BringzzButton>
                </div>
            </BringzzBottomDrawer>
        </div>
    );
};

export default CameraPage;
