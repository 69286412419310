import React, { useEffect } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon
} from "@bringzz/components";
import { useOrder } from 'context/OrderContext';
const MatchmakingPickupFinishedPage = () => {
    const { navigate } = useNavigation();
    const { orderDetails, resetOrderContext } = useOrder();

    useEffect(() => {
        if (orderDetails) {
            resetOrderContext();
        }
    }, [orderDetails, resetOrderContext]);

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={() => navigate('/home')}
                    />
                ),
            }}
            authenticated={true}
            footerHover={true}
            footer={
                <div className='px-6 py-4'>
                    <BringzzButton size='lg' className='bg-magic-lilac w-full flex justify-center' onClick={() => navigate('/home')}>Continue</BringzzButton>
                </div>
            }
        >

            <div className='px-6'>
                <div className='py-8 space-y-2 flex flex-col items-center'>
                    <div className=''>
                        <BringzzIcon
                            icon="IconMountain"
                            library="Custom"
                            className="flex items-center justify-center h-36 w-36 bg-gray-100 rounded-full "
                            size="90"
                        />
                    </div>
                    <BringzzText tag='h1' className='text-center'>Thank you!</BringzzText>
                    <BringzzText tag='h4' className='w-64 text-center font-normal'>The shopper is now responsible for delivering the order to customer.</BringzzText>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default MatchmakingPickupFinishedPage;
