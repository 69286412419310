import React, { useState, useEffect } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzButton, BringzzIcon, BringzzText, BringzzInput
} from "@bringzz/components";
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

const EditPharmacyProfile = () => {
    const { backPress, navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const { user } = useAuth();
    const [pharmacyNameValue, setPharmacyNameVAlue] = useState('');
    const [pharmacyAtuValue, setPharmacyAtuValue] = useState('');
    const [pharmacyBioValue, setPharmacyBioValue] = useState('');
    const [pharmacyId, setPharmacyId] = useState('');

    useEffect(() => {
        const fetchPharmacyInfo = async () => {
            try {
                const response = await sendRequest({
                    method: 'GET',
                    url: `/module/profile/getPharmacyInfo`,
                });
                if (response.status === 'success') {
                    const { name, atu, bio, id } = response.data;
                    setPharmacyNameVAlue(name);
                    setPharmacyAtuValue(atu);
                    setPharmacyBioValue(bio);
                    setPharmacyId(id);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchPharmacyInfo();
    }, [sendRequest]);

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Pharmacy Profile</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            console.log({
                                pharmacyname: pharmacyNameValue,
                                atu: pharmacyAtuValue,
                                pharmacyBio: pharmacyBioValue
                            });

                            await sendRequest({
                                method: 'POST',
                                url: `/module/profile/changePharmacyInfo`,
                                data: {
                                    id: pharmacyId,
                                    name: pharmacyNameValue,
                                    atu: pharmacyAtuValue,
                                    bio: pharmacyBioValue
                                }
                            }).then((response) => {
                                if (response.status === 'success')
                                    navigate(`/settings/pharmacy/info`, false);
                            }).catch((error) => {
                                console.error(error);
                            });
                        }}
                        disabled={loading || (!pharmacyNameValue && !pharmacyAtuValue && !pharmacyBioValue)}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzInput
                    id='pharmacyname'
                    label="Pharmacy Name"
                    containerClassname="w-full"
                    placeholder="Pharmacy Name"
                    value={pharmacyNameValue} // Use value instead of defaultValue
                    onChange={(e, isValid, newValue) => {
                        setPharmacyNameVAlue(newValue);
                    }}
                />
                <BringzzInput
                    id='atu'
                    label="Tax ID / ATU"
                    containerClassname="w-full"
                    placeholder="Tax ID / ATU"
                    value={pharmacyAtuValue} // Use value instead of defaultValue
                    onChange={(e, isValid, newValue) => {
                        setPharmacyAtuValue(newValue);
                    }}
                />
                <BringzzInput
                    id='bio'
                    type='textarea'
                    label="Pharmacy Bio"
                    className='h-72'
                    containerClassname="w-full"
                    placeholder='Pharmacy Bio'
                    value={pharmacyBioValue} // Use value instead of defaultValue
                    onChange={(e, isValid, newValue) => {
                        setPharmacyBioValue(newValue);
                    }}
                />
            </div>
        </BringzzPageContainer>
    );
};

export default EditPharmacyProfile;
