import React, { useEffect } from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzQrCode } from "@bringzz/components"
import useRequest from 'hooks/useRequest';
import { useOrder } from 'context/OrderContext';
import { useSocket } from 'context/SocketContext';

const QrCodePage = () => {
    const { backPress, navigate } = useNavigation();
    const { sendRequest } = useRequest()
    const { orderDetails, proceedToNextStep, refetchOrder } = useOrder()
    const { sendMessage, onRefetchData } = useSocket();

    useEffect(() => {
        const cleanupRefetchData = onRefetchData((data) => {
            console.log({ data });
            if (data.notificationType.includes("ORDER_PROGRESS_")) {
                if (parseInt(data.notificationType.split("_")[2])) {
                    // proceedToNextStep();
                    navigate(`/matchmaking/delivery/${data.notificationType.split("_")[2]}`)
                } else {
                    console.error(`qrcode page: matchmaking/${data.notificationType.split("_")[2]} not integer`);
                }
            }
            return;
        });

        refetchOrder()

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [onRefetchData])

    return (
        <BringzzPageContainer
            header={{
                right: <BringzzIcon
                    size="18"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Rezept QR Code</BringzzText>,
            }}>

            <div className='flex justify-center items-center w-full h-full pt-52'>
                <BringzzQrCode value="123456789" />
            </div>
        </BringzzPageContainer>

    )
}

export default QrCodePage;