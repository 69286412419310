import { BringzzText, BringzzButton } from "@bringzz/components";
import RocketImage from 'assets/images/Rocket.png'

export const deliverySegments = ({ navigate, loading }) => [
    {
        status: "On your way to the customer...",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/navigation')}>Get directions</BringzzButton>
        </div>
    },
    {
        status: "You've arrived at the customer!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Let the customer scan your QR-Code</BringzzText>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/qrcode')}>Identify yourself</BringzzButton>
        </div>
    },
    {
        status: "On your way to the pharmacy!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/navigation')}>Get directions</BringzzButton>
        </div>
    },
    {
        status: "You've arrived at the pharmacy!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Let the pharmacist scan your QR-Code</BringzzText>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/qrcode')}>Identify yourself</BringzzButton>
        </div>
    },
    {
        status: "Wait for the pharmacist!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Wait for the pharmacist to confirm the order</BringzzText>
        </div>
    },
    {
        status: "Take charge of the order!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Scan the QR-Code on the order bag</BringzzText>
            <BringzzButton onClick={() => navigate('/camera/delivery/collect')} className='bg-magic-lilac mt-5'>Make collection</BringzzButton>
        </div>
    },
    {
        status: "Delivering order to customer...",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Drive bacl with the order to the customer</BringzzText>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/navigation')}>Get directions</BringzzButton>
        </div>
    },
    {
        status: "You've arrived at the customer!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Identify yourself and hand over the order</BringzzText>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/qrcode')}>Identify yourself</BringzzButton>
        </div>
    },
    {
        status: "Handover the order to the customer",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Scan the QR-Code on the order bag to confirm that you have delivered the order.</BringzzText>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/camera/delivery/confirm')}>Confirm handover</BringzzButton>
        </div>
    },
    {
        status: "Order completed!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Order completed! Thank you for delivering the order.</BringzzText>
        </div>
    },
]

export const packageSegments = ({ navigate, processOrder, loading }) => [
    {
        status: "Get ready to prepare order...",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Time to prepare the order!"}</BringzzText>
            <BringzzButton
                className="bg-magic-lilac"
                onClick={() => processOrder()}
                disabled={loading}
            >Process Order</BringzzButton>
        </div>
    },
    {
        status: "Matching shopper...",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Waiting for a shopper to accept the order!"}</BringzzText>
        </div>
    },
    {
        status: "Shopper is on his way to the customer.",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Waiting for the shopper."}</BringzzText>
        </div>
    },
    {
        status: "Shopper arrived at the customer.",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Waiting for the shopper."}</BringzzText>
        </div>
    },
    {
        status: "Shopper on his way to you.",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Waiting for the shopper."}</BringzzText>
        </div>
    },
    {
        status: "Identify Shopper",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Scan the QR-Code on the shopper's phone."}</BringzzText>
            <BringzzButton onClick={() => navigate('/camera/package/identify')} className='bg-magic-lilac mt-5'>Identify Shopper</BringzzButton>
        </div>
    },
    {
        status: "Bring the order to the shopper",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Scan the bags to confirm that you have brought the order to the shopper."}</BringzzText>
            <BringzzButton onClick={() => navigate('/camera/package/confirm')} className='bg-magic-lilac mt-5'>Confirm order</BringzzButton>
        </div>
    },
    {
        status: "Waiting for the shopper to confirm the order",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Waiting for the shopper to confirm the order."}</BringzzText>
        </div>
    },
    {
        status: "Order completed!",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-18 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal mb-4"}>{"Shopper on his way to the customer!"}</BringzzText>
        </div>
    },
]

export const pickupSegments = ({ navigate, loading }) => [
    {
        status: "Matching pharmacy...",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Waiting for a pharmacy to accept request!</BringzzText>
        </div>
    },
    {
        status: "Order is being prepared",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Pharmacy matched! Your order is now
                being prepared.</BringzzText>
        </div>
    },
    {
        status: "Matching shopper...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Order ready!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Now waiting for shopper to accept request...</BringzzText>
        </div>
    },
    {
        status: "Shopper is on his way...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Shopper matched!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>He is on his way to pick up your receipt from you...</BringzzText>
        </div>
    },
    {
        status: "Handover prescription...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Time to handover your prescription!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>{"Scan the QR-Code on your shopper's phone and handover the prescription."}</BringzzText>
            <BringzzButton onClick={() => navigate('/camera/pickup/handover')} className='bg-magic-lilac mt-5'>Handover prescription</BringzzButton>
        </div>
    },
    {
        status: "Shopper on the way to the pharmacy",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Handover complete!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Your shopper is now on his way to pick up your order.</BringzzText>
        </div>
    },
    {
        status: "Shopper arrived at the pharmacy",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Waiting for shopper to identify himself</BringzzText>
        </div>
    },
    {
        status: "Shooper identified at the pharmacy",
        subStatus: '',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Waiting for shopper to pickup your order</BringzzText>
        </div>
    },
    {
        status: "Pharmacist confirmed the order",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Order handed over to the shopper</BringzzText>
        </div>
    },
    {
        status: "Order picked up...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Your order has been picker up!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Shopper is now on his way back to deliver your order..</BringzzText>
        </div>
    },
    {
        status: "Shopper has arrived...",
        subStatus: 'Delivered at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Shopper has arrived with your order!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Scan the QR-Code on the shopper phone to identify him.</BringzzText>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/camera/pickup/identify')}>Identify shopper</BringzzButton>
        </div>
    },
    {
        status: "Ready for collection...",
        subStatus: 'Delivered at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Wait for the shopper to handover your order.</BringzzText>
        </div>
    },
    {
        status: "Confirm your order...",
        subStatus: 'Delivered at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Scan the QR-Code on your order bag to confirm that you have received the correct order.</BringzzText>
            <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/camera/pickup/confirm')}>Confirm delivery</BringzzButton>
        </div>
    },
    {
        status: "Order completed!",
        subStatus: 'Delivered at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Order completed!</BringzzText>
        </div>
    },
]