import React, { useState, useEffect, useRef } from 'react';
import {
    BringzzAccordion,
    BringzzButton,
    BringzzPromoBanner,
    BringzzText,
    BringzzTopBar,
    BringzzBottomDrawer,
    BringzzRotatingSelect,
    BringzzBasketProduct,
    BringzzIcon,
    BringzzOrderFlow
} from '@bringzz/components';
import CheckoutAddressSelect from '../../components/CheckoutAddressSelect';
import CheckoutSummary from 'components/CheckoutSummary';
import AddLocation from 'components/AddLocation';
import { useNavigation } from '../../context/NavigationContext';
import useRequest from 'hooks/useRequest';
import BringzzCheckoutPicker from 'components/BringzzCheckoutPicker';
import BasketRecommendationSection from './BasketRecommendationSection';
import { useBasket } from '../../context/BasketContext';

const BasketPage = () => {
    const { navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const { basketItems, removeFromBasket, updateQuantity, getBasketTotal, getBasketSubTotal, getDeliveryCost } =
        useBasket();
    const [customAddress, setCustomAddress] = useState(null);
    const [pharmacies, setPharmacies] = useState([])
    const [showingDrawer, setShowingDrawer] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showingSelect, setShowingSelect] = useState(false);
    const [showingAddressSelect, setShowingAddressSelect] = useState(false);
    const [showingCheckoutModal, setShowingCheckoutModal] = useState(false);
    const [selectingLocation, setSelectingLocation] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const orderFlowRef = useRef();
    const [currentScreen, setCurrentScreen] = useState('basket');
    const handleProcessOrder = () => {
        orderFlowRef.current.goToNextScreen();
    };
    const center = { lat: 48.192, lng: 16.3671 };
    const zoom = 12;

    const closeMapAddressSelect = location => {
        if (location) {
            setCustomAddress(location);
        }
        setSelectingLocation(false);
    };

    const handleQuantityChange = (newQuantity, index) => {
        const product = basketItems[index];
        updateQuantity(product.id, newQuantity);
    };

    const handleDeleteProduct = index => {
        const product = basketItems[index];
        removeFromBasket(product.id);
    };

    const openAddressSelection = () => {
        setShowingDrawer(true);
        setShowingAddressSelect(true);
    };

    const closeAddressSelection = () => {
        setShowingDrawer(false);

        // wait for drawer animation animation to finish
        setTimeout(() => {
            setShowingAddressSelect(false);
        }, 500);
    };

    const openCheckoutModal = async () => {
        try {
            const response = await sendRequest({
                method: 'POST',
                url: `/module/order/pharmacyMatching`,
                data: {
                    "addressId": 1,
                    "radius": 3,
                    "products": basketItems,
                    "limit": 5,
                    "offset": 0
                }
            })

            if (response && response.data && response.data.pharmacies && response.data.pharmacies.length) {
                setPharmacies(response.data.pharmacies)
                setShowingDrawer(true);
                setShowingCheckoutModal(true);
            } else {
                alert(response.message)
            }
        } catch (error) {
            console.log("error");
            console.error(error);
        }
    };

    const closeCheckoutModal = async () => {
        setShowingDrawer(false);
        // TODO: Update basket
        // const response = await sendRequest({
        // 	method: 'POST',
        // 	url: `/basket/checkout`,
        // 	data: {
        // 		pharmacyId: "1",
        // 		deliveryAdressId: "1",
        // 		paymentMethodId: 1,
        // 		deliveryTime: 1,
        // 		products: [{}],
        // 		tip: 1.2,
        // 		totalPrice: 18
        // 	}
        // })
        // wait for drawer animation animation to finish
        setTimeout(() => {
            setShowingCheckoutModal(false);
        }, 500);
    };

    const onCloseSelect = () => {
        setShowingDrawer(false);

        // wait for drawer animation animation to finish
        setTimeout(() => {
            setShowingSelect(false);
        }, 500);
    };

    // Add handleCardSelection function
    const handleCardSelection = cardId => {
        setSelectedCardId(cardId);
    };

    return (
        <div className='h-full relative'>
            {selectingLocation ? (
                <AddLocation
                    center={center}
                    zoom={zoom}
                    close={closeMapAddressSelect}
                ></AddLocation>
            ) : (
                <>
                    {/* {showingSelect && (
						<BringzzBottomDrawer
							isOpen={showingDrawer}
							close={onCloseSelect}
							title='Select...'
						>
								<div className='flex items-center relative gap-x-4 justify-center'>
									<div className='h-12 absolute top-1/2 right-0 w-full border-y -translate-y-1/2'></div>
									<div>
										<BringzzRotatingSelect
											options={options}
											onSelect={handleChange}
											onClose={onCloseSelect}
										/>
									</div>
								</div>

								<div className='flex justify-center items-center mt-4'>
									<BringzzButton
										className='bg-magic-lilac'
										onClick={onCloseSelect}
									>
										Apply
									</BringzzButton>
								</div>
						</BringzzBottomDrawer>
					)}
					{showingAddressSelect && (
						<BringzzBottomDrawer
							isOpen={showingDrawer}
							close={closeAddressSelection}
							title='Select adress to continue'
						>
							<CheckoutAddressSelect
								close={closeAddressSelection}
								customAddress={customAddress}
								setShowingMapSelect={setSelectingLocation}
							></CheckoutAddressSelect>
						</BringzzBottomDrawer>
					)}*/}

                    {showingCheckoutModal && (
                        <BringzzBottomDrawer
                            isOpen={showingDrawer}
                            close={closeCheckoutModal}
                            title='Checkout...'
                        >
                            <CheckoutSummary
                                deliveryType={selectedCardId}
                                close={closeCheckoutModal}
                                gotToNextScreen={handleProcessOrder}
                                pharmacy={pharmacies[0]}

                            />
                        </BringzzBottomDrawer>
                    )}

                    <BringzzTopBar
                        sections={[
                            { name: 'location', alignment: 'start', value: 'Wien' },
                            { name: 'title', alignment: 'center', value: 'YOUR BASKET' },
                            {
                                name: 'notification',
                                alignment: 'end',
                                value: true,
                                onClick: () => navigate('/notifications')
                            }
                        ]}
                    />
                    <BringzzPromoBanner
                        header='Free delivery – today only!'
                        subHeader={
                            <>
                                Lectus est mi augue amet fames ultrices.{' '}
                                <span className='underline'>Promo Terms</span>
                            </>}>
                    </BringzzPromoBanner>

                    <div className='p-4'>
                        <BringzzText tag='h1' className='font-medium'>
                            YOUR BASKET
                        </BringzzText>
                    </div>
                    <div className='px-4'>
                        {basketItems &&
                            basketItems.length > 0 &&
                            basketItems.map((product, index) => (
                                <div
                                    key={product.id || index}
                                    className='flex justify-between items-start border-b pb-4 mb-4'
                                >
                                    {/* Left Section: Product Image */}
                                    <div className='w-1/5'>
                                        <img
                                            src={product.imgUrl || '/placeholder.png'}
                                            alt={product.name || 'Product Image'}
                                            className='w-full h-auto rounded'
                                        />
                                    </div>

                                    {/* Middle Section: Product Details and Quantity Controls */}
                                    <div className='w-3/5 px-2 flex flex-col'>
                                        <BringzzText tag='h4' className='font-semibold text-sm'>
                                            {product.name || 'Product Name'}
                                        </BringzzText>
                                        <BringzzText className='text-gray-500 text-xs'>
                                            {product.manufacturer || 'Manufacturer'}
                                        </BringzzText>
                                        <div className='text-xs mt-1'>
                                            <span>{`${product.packaging} | ${product.packageQuantity}` || ''}</span>
                                        </div>

                                        {/* Quantity Controls */}
                                        <div className='flex items-center mt-2'>
                                            {product.quantity > 0 ? (
                                                <button
                                                    className='border border-gray-400 rounded-full px-2'
                                                    onClick={() =>
                                                        handleQuantityChange(
                                                            Math.max(product.quantity - 1, 0),
                                                            index
                                                        )
                                                    }
                                                >
                                                    –
                                                </button>
                                            ) : (
                                                <BringzzIcon
                                                    size="22"
                                                    icon="IconTrashCan"
                                                    folder="LineIcons"
                                                    className="cursor-pointer"
                                                    onClick={() => handleDeleteProduct(index)}
                                                />

                                            )}
                                            <span className='mx-2'>{product.quantity}</span>
                                            <button
                                                className='border border-gray-400 rounded-full px-2'
                                                onClick={() =>
                                                    handleQuantityChange(product.quantity + 1, index)
                                                }
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>

                                    {/* Right Section: Price */}
                                    <div className='w-1/5 text-right flex flex-col items-end'>
                                        <div className='text-lg font-semibold text-black'>
                                            € {product.price ? (product.price * product.quantity).toFixed(2) : '0.00'}
                                        </div>
                                        {/* <div className='text-gray-400 line-through text-sm'>
											€ 12,00
										</div> */}
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className='px-4'>
                        <div className='py-4 border-b'>
                            <BringzzButton
                                className='border border-black'
                                icon={{ variant: 'IconPlus', folder: 'LineIcons', size: '10' }}
                                onClick={() => navigate('/search')}
                            >
                                {/* <span
										className='text-sm'
										onClick={() => {
											const productsCopy = [...products];
											productsCopy.push({
												name: `Product Name ${products.length + 1}`,
												manufacturer: `Manufacturer ${products.length + 1}`,
												amount: '24 Stk | Kapseln',
												quantity: 1,
												imgUrl: '/product.png',
												price: 10
											});
											setProducts(productsCopy);
										}}
									>
										add Item
									</span> */}
                            </BringzzButton>
                        </div>
                    </div>

                    {/* Promo code section */}
                    {/* <div className='p-4'>
                        <BringzzAccordion
                            defaultValue={false}
                            title={
                                <BringzzText tag='h4' className='font-bold'>
                                    Promo Code
                                </BringzzText>
                            }
                        >
                            <BringzzButton className='bg-sand'>Promo10</BringzzButton>
                        </BringzzAccordion>
                    </div> */}

                    <div className='px-4'>
                        <div className='py-4 border-b'>
                            <div className='justify-between flex mb-2'>
                                <BringzzText tag='h4' className='font-regular'>
                                    Subtotal
                                </BringzzText>
                                <BringzzText tag='h4' className='font-regular'>
                                    € {getBasketSubTotal()}
                                </BringzzText>
                            </div>
                            <div className='justify-between flex mb-2'>
                                <BringzzText tag='h4' className='font-regular'>
                                    Delivery fee & handling
                                </BringzzText>
                                <BringzzText tag='h4' className='font-regular'>
                                    € {getDeliveryCost()}
                                </BringzzText>
                            </div>
                            <div className='justify-between flex mb-2'>
                                <BringzzText tag='h4' className='font-bold'>
                                    Est. Total
                                </BringzzText>
                                <BringzzText tag='h4' className='font-bold'>
                                    € {(getBasketTotal())}
                                </BringzzText>
                            </div>
                        </div>
                        <div></div>
                    </div>

                    <BasketRecommendationSection />
                    {/* <BringzzOrderFlow
        ref={orderFlowRef}
        flowType={"SHOPPER"}
        currentScreen={currentScreen}
        onScreenChange={setCurrentScreen}
      /> */}

                    <div className='sticky bottom-24 md:bottom-0 p-4 w-full shadow-top z-20 bg-white'>
                        <BringzzCheckoutPicker onCardSelect={handleCardSelection} />

                        <div className='w-full'>
                            <BringzzButton
                                className='bg-magic-lilac rounded-full flex justify-center font-semibold w-full'
                                onClick={openCheckoutModal}
                                disabled={selectedCardId == 1 || selectedCardId == 2 || basketItems.length == 0 || loading}
                            >
                                {!loading ? "Checkout" : "Loading..."}
                            </BringzzButton>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BasketPage;
