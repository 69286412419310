/* eslint-disable no-undef */
const DefaultMenuList = [
	{
		label: 'Home',
		icon: 'IconBringzz',
		folder: 'CustomIcons',
		url: '/home'
	},
	{
		label: 'Search',
		icon: 'IconSearchAlt',
		folder: 'LineIcons',
		url: '/search'
	},
	{
		label: 'Scan',
		icon: 'IconBarcode',
		folder: 'LineIcons',
		url: '/scan'
	},
	{
		label: 'Basket',
		icon: 'IconShoppingBag14',
		folder: 'LineIcons',
		url: '/basket'
	},
	{
		label: 'Profile',
		icon: 'IconUserAlt1',
		folder: 'LineIcons',
		url: '/settings'
	}
];

const PharmacistMenuList = [
	{
		label: 'Home',
		icon: 'IconBringzz',
		folder: 'CustomIcons',
		url: '/home'
	},
	{
		label: 'Search',
		icon: 'IconSearchAlt',
		folder: 'LineIcons',
		url: '/search'
	},
	{
		label: 'Post',
		icon: 'IconCirclePlus',
		folder: 'LineIcons',
		url: '/posts'
	},
	{
		label: 'Basket',
		icon: 'IconShoppingBag14',
		folder: 'LineIcons',
		url: '/basket'
	},
	{
		label: 'Profile',
		icon: 'IconUserAlt1',
		folder: 'LineIcons',
		url: '/settings'
	}
];

const hoursOptions = [
	{ label: '00', value: 0 },
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
	{ label: '5', value: 5 },
	{ label: '6', value: 6 },
	{ label: '7', value: 7 },
	{ label: '8', value: 8 },
	{ label: '9', value: 9 },
	{ label: '10', value: 10 },
	{ label: '11', value: 11 },
	{ label: '12', value: 12 },
	{ label: '13', value: 13 },
	{ label: '14', value: 14 },
	{ label: '15', value: 15 },
	{ label: '16', value: 16 },
	{ label: '17', value: 17 },
	{ label: '18', value: 18 },
	{ label: '19', value: 19 },
	{ label: '20', value: 20 },
	{ label: '21', value: 21 },
	{ label: '22', value: 22 },
	{ label: '23', value: 23 },
	{ label: '24', value: 24 }
];

const generateMinutesOptions = () => {
	let array = [];
	for (let index = 0; index < 60; index++) {
		array.push({ value: index, label: index.toString().padStart(2, '0') });
	}
	return array;
};
const minutesOptions = generateMinutesOptions();

const INSTRUCTIONS = {
	PICKUP_ORDER_CREATED_INSTRUCTIONS: {
		steps: [
			{
				headline: 'Headline1',
				textContent:
					'Proin sed blandit eros. Phasellus ac nisl enim. Sed sit amet quam bibendum, accumsan enim sit amet, maximus sapien. Vivamus mauris risus, malesuada id quam aliquet, rhoncus fermentum turpis. Fusce accumsan eu diam a aliquam. Aliquam congue fringilla libero, venenatis egestas elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In nec ultricies risus.',
				image: '/basket-service-1.png',
			},
			{
				headline: 'Headline2',
				textContent:
					'Phasellus ac nisl enim. Sed sit amet quam bibendum, accumsan enim sit amet, maximus sapien. Vivamus mauris risus, malesuada id quam aliquet, rhoncus fermentum turpis.',
				image: '/basket-service-2.png',
			},
			{
				headline: 'Headline3',
				textContent:
					'Aliquam congue fringilla libero, venenatis egestas elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In nec ultricies risus.',
				image: '/basket-service-3.png',
			},
		]
	},
	PICKUP_IDENTIFY_SHOPPER_INSTRUCTIONS: {
		steps: [
			{
				headline: 'Identify the Shopper',
				textContent:
					'Proin sed blandit eros. Phasellus ac nisl enim. Sed sit amet quam bibendum, accumsan enim sit amet, maximus sapien. Vivamus mauris risus, malesuada id quam aliquet, rhoncus fermentum turpis. Fusce accumsan eu diam a aliquam. Aliquam congue fringilla libero, venenatis egestas elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In nec ultricies risus.',
				image: '/basket-service-1.png',
			},
			{
				headline: 'Scan the QR Code',
				textContent:
					'Proin sed blandit eros. Phasellus ac nisl enim. Sed sit amet quam bibendum, accumsan enim sit amet, maximus sapien. Vivamus mauris risus, malesuada id quam aliquet, rhoncus fermentum turpis. Fusce accumsan eu diam a aliquam. Aliquam congue fringilla libero, venenatis egestas elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In nec ultricies risus.',
				image: '/basket-service-1.png',
			},
		]
	}
};

const USER_ROLES = {
	PHARMACY_ROLE: 1,
	CONSUMER_ROLE: 5,
	SHOPPER_ROLE: 4
};

const ORDER_STATUS = {
	OPEN: 0,
	MATCHING_PHARMACY: 1,
	PHARMACY_MATCHED: 2,
	BEING_PREPARED: 3,
	PREPARED: 4,
	SHOPPER_MATCHED: 5,
	SHOPPER_ARRIVED_AT_CUSTOMER: 6,
	SHOPPER_IDENTIFIED_BY_CUSTOMER: 7,
	SHOPPER_ARRIVED_AT_PHARMACY: 8,
	SHOPPER_IDENTIFIED_BY_PHARMACY: 9,
	ORDER_CONFIRMED_BY_PHARMACY: 10,
	ORDER_CONFIRMED_BY_SHOPPER: 11,
	SHOPPER_ARRIVED_AT_CUSTOMER_WITH_PACKAGE: 12,
	SHOPPER_IDENTIFIED_BY_CUSTOMER_WITH_PACKAGE: 13,
	ORDER_CONFIRMED_BY_SHOPPER_WITH_PACKAGE: 14,
	DONE: 15,
	CANCELED: 16,
	ERROR: 17
};

module.exports = {
	DefaultMenuList, PharmacistMenuList, hoursOptions, minutesOptions, INSTRUCTIONS, USER_ROLES, ORDER_STATUS
};
