import React from 'react';
import { BringzzAccordion, BringzzText, BringzzIcon, BringzzBasketProduct } from "@bringzz/components";

const BringzzOrderCardSmallAccordion = ({ totalPrice, products, deliveryFee = 2, defaultExpanded = false }) => {
    const subtotal = totalPrice - deliveryFee;

    return (
        <BringzzAccordion
            defaultValue={defaultExpanded}
            title={<div className='flex space-x-4'>
                <div>
                    <BringzzIcon
                        folder='LineIcons'
                        icon='IconCheckBoxAlt'
                        size='22'
                        className='p-3 rounded-full' />
                </div>
                <div>
                    <BringzzText tag='h3'>Order</BringzzText>
                    <BringzzText tag='h4' className={"font-normal"}>Total: €{totalPrice || 0}</BringzzText>
                </div>
            </div>}
            className='border-t'
        >
            {products && products.map((product, index) => {
                // TODO: get imgUrl from backend
                const productWithImage = {
                    ...product,
                    imgUrl: product.imgUrl || 'https://placehold.co/75x75'
                };
                return (
                    <BringzzBasketProduct
                        key={index + 'product'}
                        product={productWithImage}
                        mode={"view"}
                    />
                );
            })}
            <div className='py-3'>
                <div className='flex justify-between'>
                    <BringzzText tag='h4' className="font-normal">Subtotal</BringzzText>
                    <BringzzText tag='h4' className="font-normal">€ {subtotal || 0}</BringzzText>
                </div>
                <div className='flex justify-between'>
                    <BringzzText tag='h4' className="font-normal">Delivery fee & handling</BringzzText>
                    <BringzzText tag='h4' className="font-normal">€ {deliveryFee}</BringzzText>
                </div>
                <div className='flex justify-between'>
                    <div className='flex space-x-1 items-center'>
                        <BringzzText tag='h4'>Total</BringzzText>
                        <BringzzIcon
                            icon="IconQuestionCircle"
                            folder="LineIcons"
                            className="h-4 w-4 cursor-pointer text-magic-lilac"
                            size="16"
                        />
                    </div>
                    <BringzzText tag='h4'>€ {totalPrice || 0}</BringzzText>
                </div>
            </div>
        </BringzzAccordion>
    );
};

export default BringzzOrderCardSmallAccordion; 