import { useState, useCallback } from 'react';
/**
 * @typedef {Object} User
 * @property {number} id - Unique identifier for the user
 * @property {string} created - Creation date 
 * @property {number} createdBy - ID of user who created this record
 * @property {string} updated - Last update date 
 * @property {number} updatedBy - ID of user who last updated this record
 * @property {string} integrationId - External system identifier
 * @property {string} title - User's title (e.g., "Dr.", "Mr.", "Ms.")
 * @property {number} gender - Gender identifier (1: Male, 2: Female)
 * @property {string} username - Username for display
 * @property {string} lastName - User's last name
 * @property {string} firstName - User's first name
 * @property {string} login - Email used for login
 * @property {string} loginType - Authentication method
 * @property {string} password - Hashed password
 * @property {number} role - User's system role
 * @property {string} emailAddress - Contact email address
 * @property {string} registrationDate - Date user registered 
 * @property {string|null} lastLoginDate - Last login timestamp
 * @property {string} phoneNumber - Contact phone number
 * @property {string} birthday - Birth date 
 * @property {string|null} userPic - Profile picture URL
 * @property {string} description - User's bio or description
 * @property {number} userRole - Specific role ID (4: Shopper, 1: Pharmacist, 5: Consumer)
 * @property {string} languageSkills - Known languages
 * @property {string} preferredLanguage - UI language preference
 * @property {number} appSound - Sound settings (0: Off, 1: On)
 * @property {number} loginFaceId - Face ID authentication (0: Disabled, 1: Enabled)
 * @property {number} getNotification - Notification settings (0: Off, 1: On)
 * @property {number} locationTracking - Location tracking consent (0: Off, 1: On)
 * @property {number} webAppTracking - Web tracking consent (0: Off, 1: On)
 * @property {number} acceptedTermsConditions - Terms acceptance (0: No, 1: Yes)
 * @property {string} role_Dyn_Sort - Role name for display/sorting
 * @property {string} fullName - Computed full name
 * @property {string} initials - User initials for display
 */

/**
 * Custom hook for managing user state
 * @returns {{
 *   user: User|null,
 *   setUser: (userData: User|null) => void,
 *   getUser: () => User|null
 * }}
 */
const useUser = () => {
    /** @type {[User|null, (userData: User|null) => void]} */
    const [user, setUserState] = useState(() => {
        try {
            const savedUser = localStorage.getItem('user');
            return savedUser ? JSON.parse(savedUser) : null;
        } catch (error) {
            return null;
        }
    });

    const setUser = useCallback((userData) => {
        try {
            const userDataString = JSON.stringify(userData);
            localStorage.setItem('user', userDataString);
            setUserState(userData);
        } catch (error) {
            console.error('Error setting user data to localStorage:', error);
        }
    }, []);

    const getUser = useCallback(() => {
        return user;
    }, [user]);

    return { getUser, setUser, user };
};

export default useUser;