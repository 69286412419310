import React, { useEffect } from 'react';
import {
    BringzzPageContainer,
    BringzzText,
    BringzzIcon,
    BringzzProgressBar,
    BringzzButton
} from '@bringzz/components';
import BringzzNavigationMap from 'components/BringzzNavigationMap';
import classNames from 'classnames';
import { useOrder } from 'context/OrderContext';
import { useNavigation } from 'context/NavigationContext';
import useRequest from 'hooks/useRequest';
import { deliverySegments } from 'constants/orderSegments';

const NavigationPage = () => {
    const { orderDetails, activeStep, proceedToNextStep, resetOrderContext } = useOrder();
    const { backPress, navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const steps = deliverySegments({ navigate, loading });

    useEffect(() => {
        if (!orderDetails) {
            resetOrderContext();
            navigate('/home');
        }
    }, [orderDetails, navigate, resetOrderContext]);

    if (!orderDetails) {
        return null;
    }

    useEffect(() => {
        if (activeStep === 0) { // shopper is going to the customer
            setTimeout(() => {
                sendRequest({
                    method: 'POST',
                    url: '/module/order/shopperHasArrivedAtTheCustomer',
                    data: {
                        orderUUID: orderDetails.integrationId
                    }
                }).then((response) => {
                    console.log("response", response);
                    if (response.status === 'success') {
                        proceedToNextStep();
                        navigate('/matchmaking/delivery/1')
                    }
                }).catch(error => console.error(error));
            }, 7000);
        }
        if (activeStep === 2) { // shopper is going to the pharmacy
            setTimeout(() => {
                sendRequest({
                    method: 'POST',
                    url: '/module/order/shopperHasArrivedAtThePharmacy',
                    data: {
                        orderUUID: orderDetails.integrationId
                    }
                }).then((response) => {
                    console.log("response", response);
                    if (response.status === 'success') {
                        proceedToNextStep();
                        navigate('/matchmaking/delivery/3')
                    }
                }).catch(error => console.error(error));
            }, 7000);
        }
        if (activeStep === 6) { // shopper is going to the customer
            setTimeout(() => {
                sendRequest({
                    method: 'POST',
                    url: '/module/order/shopperHasArrivedAtTheCustomer',
                    data: {
                        orderUUID: orderDetails.integrationId
                    }
                }).then((response) => {
                    console.log("response", response);
                    if (response.status === 'success') {
                        proceedToNextStep();
                        navigate('/matchmaking/delivery/7')
                    }
                }).catch(error => console.error(error));
            }, 7000);
        }
    }, []);

    console.log("orderDetails", orderDetails);
    return (
        <div
            className="h-screen flex flex-col"
        >
            <div className="h-full bg-slate-500">
                <BringzzNavigationMap />
            </div>
            <div className="bg-black">
                <div className='flex justify-center items-center space-x-4 p-2 bg-magic-lilac/80'>
                    <BringzzText tag='h4' className={"text-white"}>Estimated time: 10 min</BringzzText>
                    <BringzzText tag='h4' className={"text-white"}>|</BringzzText>
                    <BringzzText tag='h4' className={"text-white"}>Estimated distance: 1 km</BringzzText>
                </div>
                <div
                    className={classNames(
                        'w-full max-w-xl z-50 flex justify-center items-center bottom-24 md:!bottom-0'
                    )}
                >
                    <div className="bg-magic-lilac w-full max-w-xl relative">
                        <div className="p-4">
                            <BringzzText tag="h4" className="font-normal text-white">
                                Order #{orderDetails.id}
                            </BringzzText>
                            <BringzzText tag="h3" className="text-white">
                                {steps[activeStep].status}
                            </BringzzText>
                            <BringzzText tag="h5" className="font-light mb-3 text-white">
                                {steps[activeStep].subStatus}
                            </BringzzText>
                            {/* <BringzzProgressBar state={Number(activeStep) + 1} mode="progressOnly" segments={steps} /> */}
                        </div>
                        <hr />
                        <div className='flex space-x-4 border-b p-2'>
                            <div>
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconUser'
                                    size='22'
                                    className='p-3 rounded-full text-white' />
                            </div>
                            <div className='flex w-full justify-between text-white'>
                                {activeStep == 2 &&
                                    <div>
                                        <BringzzText tag='h3' className={"text-white"}>Pharmacy</BringzzText>
                                        <BringzzText tag='h4' className={"font-light text-white"}>{orderDetails.pharmacy.name || "pharmacy_name"}</BringzzText>
                                    </div>
                                }
                                {(activeStep == 6 || activeStep == 0) &&

                                    <div>
                                        <BringzzText tag='h3' className={"text-white"}>Customer</BringzzText>
                                        <BringzzText tag='h4' className={"font-light text-white"}>{orderDetails.customer.firstName || "customer_name"}</BringzzText>
                                    </div>
                                }
                                <div className='flex items-center space-x-2 px-4'>
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconInfoCircle'
                                        className='cursor-pointer'
                                        size='22' />
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconPhone'
                                        className={'text-white'}
                                        size='16' />
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconSupport'
                                        className={'text-white'}
                                        size='21' />
                                </div>
                            </div>
                        </div>

                        <div
                            className="flex justify-between p-2 px-4 items-center space-x-2 cursor-pointer"
                            onClick={() => {
                                // const nextStep = getCurrentPage(activeStep)
                                // console.log("nextStep", nextStep);
                                // console.log("activeStep", activeStep);

                                backPress();
                            }}
                        >
                            <div className='flex items-center space-x-2'>
                                <BringzzText tag="h4" className="text-white font-normal underline">
                                    View details
                                </BringzzText>
                                <BringzzIcon icon="IconChevronDown" folder="LineIcons" size="12" className="text-white" />
                            </div>
                            <div>
                                <BringzzButton className='border-white border text-white' onClick={() => navigate('/')}>Exit navigation</BringzzButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigationPage;