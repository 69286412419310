import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import BringzzIcon from "../BringzzIcon";
import BringzzInput from "../BringzzInput";
import BringzzBottomDrawer from "../BringzzBottomDrawer";
import { format as formatDateFn, startOfMonth, endOfMonth, eachDayOfInterval } from "date-fns";

const CustomDatePicker = ({
  className,
  onChange,
  onClose,
  format = "dd/MM/yyyy",
  maxDate,
  minDate = new Date(1950, 0, 1),
  maxYear,
  minYear,
  defaultValue = '',
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue || new Date());
  const [showPicker, setShowPicker] = useState(false);
  const [year, setYear] = useState(selectedDate.getFullYear());
  const [month, setMonth] = useState(selectedDate.getMonth());

  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(new Date(year, month)),
    end: endOfMonth(new Date(year, month))
  });

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    if (onChange) {
      onChange(formatDateFn(newDate, format));
    }
  };

  const closePicker = () => {
    setShowPicker(false);
    if (onClose) onClose();
  };

  const renderCalendar = () => (
    <div className="grid grid-cols-7 gap-1">
      {daysInMonth.map((day) => (
        <button
          key={day}
          onClick={() => handleDateChange(day)}
          className={classnames(
            "p-2 rounded",
            day.getDate() === selectedDate.getDate() &&
              day.getMonth() === selectedDate.getMonth() &&
              day.getFullYear() === selectedDate.getFullYear()
              ? "bg-blue-500 text-white"
              : "bg-gray-100"
          )}
        >
          {day.getDate()}
        </button>
      ))}
    </div>
  );

  const renderMonthYearDropdowns = () => (
    <div className="flex items-center justify-between mb-4 space-x-4">
      <div className="flex-1 overflow-x-auto flex items-center space-x-4">
        {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((m, i) => (
          <button
            key={i}
            onClick={() => setMonth(i)}
            className={classnames(
              "px-4 py-2 rounded transition-colors",
              month === i ? "bg-blue-500 text-white font-bold" : "bg-gray-100 text-gray-700"
            )}
          >
            {m}
          </button>
        ))}
      </div>
  
      <div className="flex-1 overflow-x-auto flex items-center space-x-4">
        {Array.from({ length: (maxYear || new Date().getFullYear()) - (minYear || 1950) + 1 }, (_, i) => {
          const yearValue = (minYear || 1950) + i;
          return (
            <button
              key={i}
              onClick={() => setYear(yearValue)}
              className={classnames(
                "px-4 py-2 rounded transition-colors",
                year === yearValue ? "bg-blue-500 text-white font-bold" : "bg-gray-100 text-gray-700"
              )}
            >
              {yearValue}
            </button>
          );
        })}
      </div>
    </div>
  );
  

  return (
    <div>
      <BringzzInput
        type="text"
        className={classnames(
          "form-input ignore-click-outside text-sm w-full outline-none cursor-pointer",
          className
        )}
        defaultValue={formatDateFn(selectedDate, format)}
        readOnly
        onClick={(e) => {
          e.stopPropagation();
          setShowPicker(!showPicker);
        }}
        icon={{
          right: (
            <BringzzIcon
              icon="IconCalendar"
              folder="CustomIcons"
              className="h-6 w-6 text-[#8E8E93]"
              size="24"
            />
          ),
        }}
        {...props}
      />
      <BringzzBottomDrawer
        isOpen={showPicker}
        close={closePicker}
        header={false}
      >
        <div className="p-6">
          {renderMonthYearDropdowns()}
          {renderCalendar()}
        </div>
      </BringzzBottomDrawer>
    </div>
  );
};

CustomDatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  format: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  defaultValue: PropTypes.instanceOf(Date),
  maxYear: PropTypes.number,
  minYear: PropTypes.number,
};

CustomDatePicker.defaultProps = {
  className: "",
  onChange: null,
  onClose: null,
  format: "dd/MM/yyyy",
  minDate: new Date(1990, 0, 1),
};

export default CustomDatePicker;
