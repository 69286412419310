import React, { useEffect, useState } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzInput,
    BringzzButton,
    BringzzCheckbox,
    BringzzBottomDrawer,
    BringzzRadioList,
    BringzzRadio,
    BringzzImage,
    BringzzDate,
    Mode
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { useAuth } from 'context/AuthContext';
import classNames from 'classnames';
import usePageState from 'hooks/usePageState';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let state = {
    checked: <div></div>,
    unChecked: <div></div>
};

const AddArticlePage = () => {
    let query = useQuery();
    const { backPress } = useNavigation();
    const { getUser } = useAuth()
    let id = query.get('id');
    const formData = usePageState()
    const [addPhotoDrawer, setAddPhotoDrawer] = useState(false);
    const [photo, setPhoto] = useState(null);
    const { navigate } = useNavigation();
    const [headline, setHeadline] = useState(null);
    const [leadIn, setLeadIn] = useState(null);
    const [imageCaption, setImageCaption] = useState(null);
    const [tags, setTags] = useState(null);

    const populateForm = (formData) => {
        if(!headline) {setHeadline(formData.headline || '')}
        if(!photo){setPhoto(formData.photo || '')}
        if(!leadIn){setLeadIn(formData.leadIn || '')}
        if(!imageCaption){setImageCaption(formData.imageCaption || '')}
        if(!tags){setTags(formData.tags || '')}
    }

    useEffect(() => {
        if (formData) {
            populateForm(formData)
        }
    }, [formData]);

    console.log('formData', formData)
    const handlePickerClick = async choice => {
        try {
            let base64Image = '';

            if (choice === 'camera') {
                // Handling camera input
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                const video = document.createElement('video');
                video.srcObject = stream;
                video.play();

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                video.onloadedmetadata = () => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    base64Image = canvas.toDataURL('image/jpeg');
                    // Stop the video stream
                    stream.getTracks().forEach(track => track.stop());
                    setPhoto(base64Image);

                    console.log(base64Image);
                };
            } else if (choice === 'photos') {
                // Handling gallery input
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = async event => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        base64Image = reader.result;
                        setPhoto(base64Image);
                    };
                    reader.readAsDataURL(file);
                };
                input.click();
            }
        } catch (error) {
            console.error('Error handling picker choice:', error);
        }
    };

    const handleRemovePhoto = () => {
        setPhoto(null); // Only reset the photo state
    };

    console.log('headline', headline)
    console.log('leadin', leadIn)
    console.log('photo', photo)

    return (
        (
            <BringzzPageContainer
                header={{
                    left: (
                        <BringzzIcon
                            size="22"
                            icon="IconChevronLeft"
                            folder="LineIcons"
                            className="cursor-pointer"
                            onClick={backPress}
                        />
                    ),
                    title: <BringzzText tag="h2">Post Article</BringzzText>
                }}
                footer={
                    <div className="pb-28 px-6 flex flex-row space-x-4">
                        <BringzzButton
                            size="md"
                            disabled={true}
                            className="border w-full border-black justify-center"
                        >
                            Save
                        </BringzzButton>
                        <BringzzButton
                            onClick={() =>
                                navigate(`/posts/build/article`, {
                                    state: {
                                        headline,
                                        leadIn,
                                        photo,
                                        imageCaption,
                                        tags
                                    }
                                })
                            }
                            disabled={!headline || !leadIn || !photo}
                            size="md"
                            className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
                        >
                            Share
                        </BringzzButton>
                    </div>
                }
            >
                <div className="p-4">
                    <div className="space-y-4">
                        <BringzzInput
                            label="Headline (max. XXX char)"
                            tooltip="something"
                            placeholder='Your article title'
                            defaultValue={headline}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setHeadline(value)
                            }}
                        />
                        <BringzzInput
                            label="Article Lead-In (max. XXX char)"
                            type="textarea"
                            placeholder=""
                            tooltip="something"
                            defaultRows={5}
                            defaultValue={leadIn}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setLeadIn(value)
                            }}
                        />
                    </div>
                    <div className="flex flex-col space-y-4 mt-4">
                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Main Image</label>
                            <div
                                className={`relative w-full ${photo ? 'h-48' : 'h-12'
                                    } border border-gray-300 rounded-md flex items-center justify-center bg-white transition-all duration-300 cursor-pointer`}
                                onClick={() => !photo && setAddPhotoDrawer(true)}
                            >
                                {photo ? (
                                    <div className="relative w-full h-full">
                                        <img src={photo} alt="Post" className="w-full h-full object-cover rounded-md" />
                                        <BringzzIcon
                                            size="24"
                                            icon="IconTrashCan"
                                            folder="LineIcons"
                                            className="absolute text-white top-2 right-2 cursor-pointer"
                                            onClick={handleRemovePhoto}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="flex items-center justify-between w-full px-3"
                                    >
                                        <BringzzText tag="h4" className="text-gray-500">Add image</BringzzText>
                                        <BringzzIcon
                                            size="24"
                                            icon="IconCirclePlus"
                                            folder="LineIcons"
                                            className="cursor-pointer text-gray-500"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='space-y-4 mt-4'>
                        <BringzzInput
                            label="Main Image Caption"
                            tooltip="something"
                            placeholder='Your image caption'
                            defaultValue={imageCaption}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setImageCaption(value)
                            }}
                        />
                        <BringzzInput
                            label="Tags"
                            tooltip="something"
                            placeholder='Tag X, Tag Y, Tag Z'
                            defaultValue={tags}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setTags(value)
                            }}
                        />
                    </div>
                </div>
                <BringzzBottomDrawer
                    isOpen={addPhotoDrawer}
                    title={'Select an Image'}
                    close={() => setAddPhotoDrawer(false)}
                >
                    <div className="px-4 py-6">
                        <BringzzRadioList
                            state={state}
                            className="rounded-xl w-full text-center"
                            onChange={choice => {
                                handlePickerClick(choice);
                                setAddPhotoDrawer(false);
                            }}
                        >
                            <BringzzRadio
                                value="camera"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                            >
                                <div>Camera</div>
                            </BringzzRadio>
                            <BringzzRadio
                                value="photos"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
                            >
                                <div>Photos</div>
                            </BringzzRadio>
                        </BringzzRadioList>
                    </div>
                </BringzzBottomDrawer>
            </BringzzPageContainer>
        )
    );
};

export default AddArticlePage;
