import React from 'react';
import { BringzzIcon, BringzzText } from "@bringzz/components";
import { useNavigation } from "context/NavigationContext";

const BringzzShopperCardSmall = ({ shopper }) => {
    const { navigate } = useNavigation();

    if (!shopper) {
        return (
            <div className='flex space-x-4 border-b py-1 text-black/20'>
                <div>
                    <BringzzIcon
                        folder='HealthIcons'
                        icon='IconPharmacy'
                        size='22'
                        className='p-3 rounded-full' />
                </div>
                <div className='flex w-full items-center'>
                    <BringzzText tag='h3' className='text-black/20'>Shopper</BringzzText>
                </div>
            </div>
        );
    }

    return (
        <div className='flex space-x-4 border-b py-2'>
            <div>
                <BringzzIcon
                    folder='HealthIcons'
                    icon='IconPharmacy'
                    size='22'
                    className='p-3 rounded-full' />
            </div>
            <div className='flex w-full justify-between'>
                <div>
                    <BringzzText tag='h3'>Shopper</BringzzText>
                    <BringzzText tag='h4' className={"font-light"}>{shopper.firstName || "shopper_name"}</BringzzText>
                </div>
                <div className='flex items-center space-x-2'>
                    <BringzzIcon
                        folder='LineIcons'
                        icon='IconInfoCircle'
                        size='22'
                        className='cursor-pointer'
                        onClick={() => {
                            // TODO: work on shopper details page
                            // navigate(`/shopper/${shopper.integrationId}`);
                        }} />
                    <BringzzIcon
                        folder='LineIcons'
                        icon='IconPhone'
                        size='16'
                        className='cursor-pointer'
                        onClick={() => {
                            window.open(`tel:${shopper.phoneNumber}`, '_blank');
                        }} />
                    <BringzzIcon
                        folder='LineIcons'
                        icon='IconSupport'
                        size='21'
                        className='cursor-pointer'
                        onClick={() => {
                            // TODO: work on chat page
                            // navigate(`/chat/${shopper.integrationId}`);
                            navigate(`/settings/chats/new`);
                        }} />
                </div>
            </div>
        </div>
    );
};

export default BringzzShopperCardSmall;