import React, { useState, useEffect } from 'react';
import { BringzzAccordion, BringzzButton, BringzzText } from "@bringzz/components";
import { useParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationContext';
import { useOverlay } from 'context/OverlayContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct,
    BringzzProgressBar,
    BringzzBottomDrawer,
    BringzzRadioList,
    BringzzRadio,
    BringzzInput
} from "@bringzz/components";
import { useSocket } from 'context/SocketContext';
import useRequest from 'hooks/useRequest';
import { useOrder } from 'context/OrderContext';
import RocketImage from 'assets/images/Rocket.png'
import BringzzOrderCardSmallAccordion from 'components/BringzzOrderCardSmallAccordion';
import BringzzCustomerCardSmall from 'components/BringzzCustomerCardSmall';
import BringzzShopperCardSmall from 'components/BringzzShopperCardSmall';
import { packageSegments } from 'constants/orderSegments';
import { ORDER_STATUS } from 'utils/constants';

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const MatchmakingPackagePage = () => {
    const { backPress, navigate } = useNavigation();
    const { step } = useParams();
    const [activeStep, setActiveStep] = useState(step ? parseInt(step, 10) : 0);
    const [selectedTip, setSelectedTip] = useState(null);
    const [cancelOrderDrawer, setCancelOrderDrawer] = useState(false)
    const [selectedReason, setSelectedReason] = useState()
    const { showOverlayContent, hideOverlay } = useOverlay();
    const { sendMessage, onRefetchData } = useSocket();
    const { sendRequest, data, error, loading } = useRequest();
    const { orderDetails, stopMatchingProcess, proceedToNextStep, refetchOrder, resetOrderContext } = useOrder()

    const processOrder = async () => {
        console.log("processOrder");
        console.log(orderDetails);

        // TODO: Implement packages logic
        await sendRequest({
            method: 'POST',
            url: `/module/order/orderIsReady`,
            data: {
                "orderUUID": orderDetails.integrationId,
            }
        }).then((response) => {
            if (response.status === 'success') {
                proceedToNextStep()
                setActiveStep(1)
            } else {
                console.log("orderIsReady error");
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    const segments = packageSegments({ navigate, processOrder, loading })

    console.log(orderDetails);

    const handleCancelOrderDrawer = async () => {
        setCancelOrderDrawer(!cancelOrderDrawer)
    }

    const handleCancelOrder = async () => {
        await sendRequest({
            method: 'POST',
            url: `/module/order/cancelOrder`,
            data: {
                "orderId": orderDetails.id
            }
        }).then((response) => {
            if (response.status === 'success') {
                navigate('/matchmaking/package/cancelled')
                stopMatchingProcess()
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    const handleShowOverlay = () => {
        showOverlayContent(
            <div className='h-full w-full bg-white'>
                <div className='flex justify-between p-4'>
                    <div>
                        <BringzzIcon
                            size='22'
                            icon='IconClose'
                            folder='LineIcons'
                            className='cursor-pointer'
                            onClick={hideOverlay}
                        />
                    </div>
                    <div>
                        <BringzzText tag='h3'>Cancellation fees</BringzzText>
                    </div>
                    <div></div>
                </div>
                <div className='p-4 space-y-2'>
                    <BringzzText tag='h3'>Cancellation fees</BringzzText>
                    <BringzzText tag='h4' className='font-normal'>The cancellation is free with in the first 60 sec, after that we will charge our service fee of 99cents. And depending on the process progress we will charge an additional cancellation fee of 5,99 EUR to compensate ... </BringzzText>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const cleanupRefetchData = onRefetchData((data) => {
            console.log({ data });
            if (data.notificationType.includes("ORDER_PROGRESS_")) return setActiveStep(data.notificationType.split("_")[2]);
            return;
        });

        refetchOrder()

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [onRefetchData])

    const selectTip = amount => {
        setSelectedTip(amount); // Update the selected tip
    };

    useEffect(() => {
        if (!orderDetails) {
            resetOrderContext();
            navigate('/home');
        }
    }, [orderDetails, navigate, resetOrderContext]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (orderDetails && orderDetails.deliveryStatus >= ORDER_STATUS.ORDER_CONFIRMED_BY_SHOPPER) {
                navigate('/matchmaking/package/completed');
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, [orderDetails.deliveryStatus, navigate]);

    if (!orderDetails) {
        return null;
    }

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                title: `Order #${orderDetails.id || ''}`,
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={() => navigate('/')}
                    />
                ),
                right: (
                    <BringzzButton size='sm' className='border border-black'>Help</BringzzButton>
                )
            }}
            authenticated={true}
        >
            <div className='px-6'>
                <div className='py-6'>
                    <BringzzText tag='h1' className='text-center'>{segments[activeStep].status}</BringzzText>
                    <BringzzText tag='h3' className='text-center font-normal'>{segments[activeStep].subStatus}</BringzzText>
                </div>

                <BringzzProgressBar state={Number(activeStep) + 1} mode={"custom"} segments={segments} />

                <BringzzCustomerCardSmall customer={orderDetails.customer} />

                <BringzzOrderCardSmallAccordion
                    totalPrice={orderDetails.totalPrice}
                    products={orderDetails.products}
                />

                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconTicketAlt'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full justify-between'>
                        <div>
                            <BringzzText tag='h3'>Invoice</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Invoice #2023-123456</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconEye'
                                size='22' />
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconUpload'
                                className={activeStep < 7 ? 'text-black/30' : ''}
                                size='16' />
                        </div>
                    </div>
                </div>

                <BringzzShopperCardSmall shopper={orderDetails.shopper} />

                <BringzzButton size='md' className='text-midnight-blue bg-magic-lilac mt-4' onClick={handleCancelOrderDrawer}>Add comment</BringzzButton>

                <div className='border-b mt-4'></div>

                <BringzzButton size='md' className='border border-red-600 text-red-600 mt-4' onClick={handleCancelOrderDrawer}>Cancel reservation</BringzzButton>
            </div>

            <BringzzBottomDrawer
                title='Cancel order'
                isOpen={cancelOrderDrawer}
                close={handleCancelOrderDrawer}
            >
                <div className='px-6 pb-6 space-y-4'>
                    <div>
                        <BringzzText tag='h4' className='font-normal'>Let us know why you are cancelling your order.</BringzzText>
                        <BringzzText tag='h4' className='font-normal'>Keep in mind <span onClick={handleShowOverlay} className='cursor-pointer underline'>cancellation fees</span> can apply.</BringzzText>
                    </div>
                    <div className='space-y-2'>
                        <div className='px-4'>
                            <BringzzRadioList
                                onChange={(newValue) => {
                                    setSelectedReason(newValue)
                                }}
                                state={state}
                                className="rounded-xl w-full text-center space-y-2"

                            >
                                <BringzzRadio value="1"
                                    className="p-2 py-2 hover:bg-slate- border rounded-md border-gray-400 flex"
                                >
                                    <BringzzText tag='h4'>Ordered by mistake</BringzzText>
                                </BringzzRadio>
                                <BringzzRadio value="2"
                                    className="p-2 py-2 hover:bg-slate- border rounded-md border-gray-400 flex"
                                >
                                    <BringzzText tag='h4'>{"Consumer/shopper didn't respond"}</BringzzText>
                                </BringzzRadio>
                            </BringzzRadioList>
                            <div className='mt-2'>
                                <BringzzInput placeholder={'Your custom reason'} className='border' onChange={(e, newIsValid, newValue) => {
                                    setSelectedReason(newValue)
                                }}></BringzzInput>
                            </div>
                        </div>
                        <BringzzButton onClick={handleCancelOrder} size='md' disabled={!selectedReason || loading} className='bg-magic-lilac w-full flex justify-center'>Cancel order</BringzzButton>
                        <BringzzButton onClick={handleCancelOrderDrawer} size='md' className='text-magic-lilac border border-magic-lilac w-full flex justify-center'>Cancel</BringzzButton>
                    </div>
                </div>
            </BringzzBottomDrawer>
        </BringzzPageContainer >
    );
};

export default MatchmakingPackagePage;