import React, { useState, useEffect } from 'react';
import {
  BringzzPageContainer,
  BringzzButton,
  BringzzText,
  BringzzImage,
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import classNames from 'classnames';
import usePageState from 'hooks/usePageState';
import { INSTRUCTIONS } from 'utils/constants';

const InstructionPage = () => {
  const { backPress, navigate } = useNavigation();
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const formData = usePageState();
  console.log("instructionPage")
  console.log(formData);

  if (!formData) return null

  useEffect(() => {
    console.log(formData?.instructions);

    if (formData?.instructions && INSTRUCTIONS[formData.instructions]) {
      setSteps(INSTRUCTIONS[formData.instructions].steps);
    }
  }, [formData]);

  console.log("instruction page");
  console.log(INSTRUCTIONS.PICKUP_ORDER_CREATED_INSTRUCTIONS.steps);
  console.log(INSTRUCTIONS.PICKUP_IDENTIFY_SHOPPER_INSTRUCTIONS.steps);
  console.log(currentStep);
  console.log(steps);
  const { headline, textContent, image } = steps[currentStep] || {};

  const onSkip = () => {
    if (formData)
      navigate(formData.nextStep); // Example navigation on skip
  };

  const onStart = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      navigate(formData.nextStep); // Example navigation for the last step
    }
  };

  return (
    <BringzzPageContainer
      className="h-full flex flex-col"
      header={{
        right: (
          <BringzzButton
            variant="text"
            className="text-gray-600"
            onClick={onSkip}
          >
            Skip
          </BringzzButton>
        ),
      }}
      footer={
        <div className="p-6">
          <BringzzButton
            size="lg"
            className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
            onClick={onStart}
          >
            {"Continue"}
          </BringzzButton>
        </div>
      }
      footerHover={true}
    >
      <div className="flex flex-col items-center p-6 justify-center text-center">
        <div className="mb-4 mt-40">
          <BringzzImage
            src={image}
            alt="Instruction illustration"
            className="w-32 h-32"
          />
        </div>

        {/* Progress Bar */}
        <div className="w-full px-4 py-6">
          <div className="flex h-1">
            {steps.map((_, indx) => (
              <div
                key={indx}
                className={classNames(
                  "flex flex-1 transition-all duration-500 ease-in-out",
                  indx === currentStep ? "bg-magic-lilac" : "bg-sand"
                )}
              ></div>
            ))}
          </div>
        </div>

        <BringzzText tag="h1" className="text-2xl font-bold mb-4">
          {headline}
        </BringzzText>

        <BringzzText tag="h4" className="text-midnight-blue/25 font-normal">
          {textContent}
        </BringzzText>
      </div>
    </BringzzPageContainer>
  );
};

export default InstructionPage;
