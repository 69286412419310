import React, { useState, useEffect } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
    BringzzModal,
	BringzzButton
} from '@bringzz/components';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
import { useNavigation } from 'context/NavigationContext';

function formatDate(dateString) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
}

const PharmacyTeamView = () => {
	const { backPress, navigate } = useNavigation();
	const { getUser } = useUser();
	const user = getUser();
	const { sendRequest, data: teamData, error, loading } = useRequest();
	const [selectedMember, setSelectedMember] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [team, setTeam] = useState([]);

	useEffect(() => {
		fetchTeamData();
	}, []);

	const fetchTeamData = async () => {
		try {
			const response = await sendRequest({
				method: 'GET',
				url: '/module/pharmacyteam/getPharmacyTeamMembers',
				params: {
					createdById: user.id
				}
			});

			if (response?.status === 'success' && Array.isArray(response.data)) {
				setTeam(response.data);
			} else {
				console.error('Invalid response format:', response);
				throw new Error('Invalid response format from server');
			}
		} catch (error) {
			console.error('Error fetching team data:', error);
			// The error state will be handled by useRequest hook
		}
	};

	const handleDeleteMember = async () => {
		await sendRequest({
			method: 'DELETE',
			url: `/pharmacy/team/delete`,
			data: {
				id: selectedMember.id
			}
		})
		.then(response => {
			if (response.status === 'success') {
				setModalOpen(false);
				fetchTeamData(); // Refetch team data after successful deletion
			}
		})
		.catch(error => {
			console.log(error);
		});
	};

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Team</BringzzText>
				
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => navigate('/settings/pharmacy/team/add')}
						disabled={
							loading }
					>
						Add employee(s)
					</BringzzButton>
				</div>
			}
		>
			<div className='p-4 divide-y divide-solid'>
				{loading ? (
					<div className="flex justify-center py-4">
						<BringzzText tag='h3'>Loading team members...</BringzzText>
					</div>
				) : error ? (
					<div className="flex justify-center py-4">
						<BringzzText tag='h3' className="text-red-500">
							{error?.message || 'Error loading team members. Please try again.'}
						</BringzzText>
					</div>
				) : team.length === 0 ? (
					<div className="flex justify-center py-4">
						<BringzzText tag='h3'>No team members found</BringzzText>
					</div>
				) : (
					team.map((member, index) => (
						<div
							key={member.id}
							className='flex items-center justify-between py-4 px-2 cursor-pointer'
						>
							<div className='flex flex-col'>
								<BringzzText tag='h3'>{member.userId_Dyn_Sort}</BringzzText>
								<div className='w-64'>
									<BringzzText
										tag='h3'
										className='text-normal text-black/50 whitespace-pre-line truncate'
									>
										{member.pharmacyId_Dyn_Sort}
									</BringzzText>
								</div>
							</div>
							<BringzzIcon 
								size='18' 
								onClick={() => navigate(`settings/pharmacy/team/edit?employeeId=${member.id}`)} 
								icon={'IconPencil'} 
								folder={'LineIcons'} 
							/>
							<BringzzIcon 
								size='18' 
								onClick={() => navigate(`settings/pharmacy/team/qr?employeeId=${member.id}`)} 
								icon={'IconBarcode'} 
								folder={'LineIcons'} 
							/>
							<BringzzIcon 
								size='18' 
								onClick={() => {
									setSelectedMember({
										name: member.userId_Dyn_Sort,
										id: member.id
									});
									setModalOpen(true);
								}} 
								icon={'IconTrashCan'} 
								folder={'LineIcons'} 
							/>
						</div>
					))
				)}
				{modalOpen && (
					<BringzzModal
						title={`Are you sure you want to remove ${selectedMember.name}?`}
						description="Et parturient venenatis blandit etiam ornare congue sed massa nec. Vivamus."
						onCancel={() => setModalOpen(false)}
						onConfirm={handleDeleteMember}
						confirmButtonText="Delete"
					/>
				)}
			</div>
		</BringzzPageContainer>
	);
};

export default PharmacyTeamView;
