import React, { useEffect, useState } from 'react';
import { BringzzText, BringzzIcon, BringzzButton } from "@bringzz/components";
import { useNavigation } from '../../context/NavigationContext';
import useRequest from 'hooks/useRequest';

const AddressSelect = ({ close, setAddress, className, ...props }) => {
	const { navigate } = useNavigation();
	const { sendRequest } = useRequest();
	const [addresses, setAddresses] = useState([]);

	useEffect(() => {
		const fetchAddresses = async () => {
			try {
				const response = await sendRequest({
					method: 'GET',
					url: '/module/address/getAddress',
				});
				if (response.status === 'success' && Array.isArray(response.data)) {
					setAddresses(response.data);
				}
			} catch (error) {
				console.error('Failed to fetch addresses', error);
			}
		};

		fetchAddresses();
	}, [sendRequest]);

	return (
		<div className={`py-6 ${className}`}>
			<div className='flex flex-col px-4 gap-y-4 mt-4'>
				{addresses.map((address) => (
					<div
						key={address.id}
						className='flex items-center justify-start gap-x-4 border rounded-md p-4 min-h-[75px] hover:cursor-pointer'
						onClick={() => setAddress(`${address.streetName}, ${address.cityName}, ${address.postalCode}`)}
					>
						<BringzzIcon folder='LineIcons' icon='IconBriefcase' size='20' />
						<div className='flex flex-col'>
							<BringzzText tag='h4' className='font-bold'>
								{address.label || 'Address'}
							</BringzzText>
							<BringzzText
								tag='h4'
								className='font-regular text-midnight-blue/80'
							>
								{address.streetName}, {address.cityName}, {address.postalCode}
							</BringzzText>
						</div>
					</div>
				))}
				<div
					className='flex items-center justify-start gap-x-4 border rounded-md p-4 min-h-[75px] hover:cursor-pointer'
					onClick={() => navigate('/settings/account/address?new=true')}
				>
					<BringzzIcon folder='LineIcons' icon='IconPlus' size='20' />
					<div className='flex flex-col'>
						<BringzzText tag='h4' className='font-bold'>
							Add new address
						</BringzzText>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddressSelect;
