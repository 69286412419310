import { useState, useCallback } from 'react';
import axiosInstance from '../api/axios';

const useRequest = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const sendRequest = useCallback(async (config, attempts = 0, delayMs = 1000) => {
        setLoading(true);
        setError(null);

        let lastError = null;

        for (let attempt = 0; attempt <= attempts; attempt++) {
            try {
                const response = await axiosInstance(config);
                setData(response);
                return response; // Return the response if successful
            } catch (err) {
                lastError = err;
                if (config.url) err["url"] = config.url; // Attach URL for context

                // Retry only for status 500
                if (err.response?.status === 500 && attempt < attempts) {
                    console.log(`Retrying request to ${config.url}... Attempt ${attempt + 1} of ${attempts}`);
                    await new Promise(resolve => setTimeout(resolve, delayMs));
                } else {
                    console.log(err);
                    break; // Break if not retryable or max attempts reached
                }
            } finally {
                setLoading(false);
            }
        }

        setError(lastError);
        return lastError; // Return the last error after all attempts
    }, []);

    return { sendRequest, data, error, loading };
};

export default useRequest;
