import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSocket } from './SocketContext';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const { socket } = useSocket();
    const [notifications, setNotifications] = useState([]);
    const { getUser } = useUser()
    const { sendRequest, data, error, loading } = useRequest();

    useEffect(() => {
        if (getUser()) {
            if (getUser().id) {
                const fetchData = async () => {
                    await sendRequest({
                        method: 'GET',
                        url: `/module/notification/fetchNotificationsByUser?user_id=${getUser().id}`,
                    }).then((response) => {
                        setNotifications(response.data)
                    }).catch((error) => {
                    })
                };
                // fetchData();
            }
        }
    }, [getUser]);

    useEffect(() => {
        if (socket) {
            const handleNotification = (newNotification) => {
                setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
            };

            socket.on('notifications', handleNotification);

            return () => socket.off('notification', handleNotification);
        }
    }, [socket]);

    const value = {
        notifications,
    };
    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};
export const useNotification = () => useContext(NotificationContext);
